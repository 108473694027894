import React, { Component } from "react";
import styled from "styled-components";
import { Route, withRouter } from "react-router-dom";

import strapi from "strapi-sdk-javascript/build/main";

import Login from "./Login";
import Series from "./Series";
import Photos from "./Photos";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  color: ${({ colorMode }) => colorMode.textColor};
  & input {
    color: ${({ colorMode }) => colorMode.textColor};
  }
`;

class Admin extends Component {
  state = {
    isAuth: false
  };

  getAuthentification = () => {
    this.setState({
      isAuth: true
    });
    this.props.history.push("/admin/series");
  };

  render() {
    const { isAuth } = this.state;
    // console.log("admin props: ",this.props);
    // if (isAuth) {
    return (
      <div>
        <Route
          exact
          path="/admin"
          render={props => (
            <Login
              colorMode={this.props.colorMode}
              // getAuthentification={this.getAuthentification}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/admin/series"
          render={props => (
            <Series
              // isAuthenticated={isAuth}
              photos={this.props.photos}
              strapi={this.props.strapi}
              colorMode={this.props.colorMode}
              {...props}
            />
          )}
        />
        <Route
          path="/admin/series/:serieId"
          render={props => (
            <Photos
              // isAuthenticated={isAuth}
              photos={this.props.photos}
              strapi={this.props.strapi}
              colorMode={this.props.colorMode}
              {...props}
            />
          )}
        />
      </div>
    );
    // } else {
    //   return (
    //     <Wrapper colorMode={this.props.colorMode}>
    //       <Login
    //         colorMode={this.props.colorMode}
    //         getAuthentification={this.getAuthentification}
    //       >
    //         {" "}
    //       </Login>
    //     </Wrapper>
    //   );
    // }
  }
}

export default withRouter(Admin);
