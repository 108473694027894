import React, { Component } from "react";
import styled from "styled-components";
import Serie from "./Serie";
import SecurePage from "../SecurePage";
import DefaultPage from "../DefaultPage";
import Header from "./Header";
import Editor from "./Editor";
import { EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { stateToMarkdown } from "draft-js-export-markdown";
import Video from "./VideoHome";

const Plus = props => (
  <svg width={83} height={82} {...props}>
    <g stroke="#979797" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M41.5.5v80.572M82 41H1.428" />
    </g>
  </svg>
);

const NewSerie = styled.div`
  width: 275px;
  height: 208px;
  margin: 23px;
  position: relative;
  background: rgba(124, 124, 124, 0);
  transition: background 0.5s ease;
  cursor: pointer;
  &:hover {
    background: rgba(124, 124, 124, 0.1);
  }
  & svg {
    /* content: ""; */
    /* background-image: url("/assets/icons/plus.svg"); */
    width: 82px;
    height: 82px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

const Button = styled.div`
  padding: 15px 60px;
  color: ${({ colorMode, light }) =>
    light ? colorMode.textColor : colorMode.bgColor};
  background-color: ${({ colorMode, light }) =>
    !light ? colorMode.textColor : colorMode.bgColor};
  font-family: "helvetica neue";
  font-size: 16px;
  margin: 0 15px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const SeriesContainer = styled.div`
  width: calc(100vw - 260px);
  margin: 30px 130px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const MenuWrapper = styled.div`
  display: flex;
  margin: 0 130px;
  @media (max-width: 960px) {
    justify-content: space-between;
    & > div {
      margin: 0;
    }
  }
`;

const ExpoType = styled.div`
  font-family: "helvetica neue";
  position: relative;
  font-size: 43px;
  line-height: 50px;
  cursor: pointer;
  color: ${({ colorMode }) => colorMode.textColor};
  margin-right: 35px;
  transition: opacity 0.2s ease;
  &:hover .btn-Menu {
    height: ${({ isFilter }) => (isFilter ? "20px" : "10px")};
    opacity: 1;
  }
`;

const Span = styled.span`
  position: absolute;
  top: calc(50% + 25px);
  ${"" /* display: ${({ isFilter }) => (isFilter ? "block" : "none")} */}
  width: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: ${({ isFilter }) => (isFilter ? "20px" : "0")};
  float: left;
  opacity: ${({ isFilter }) => (isFilter ? "1" : "0")};
  background-color: ${({ colorMode }) => colorMode.textColor};
  transition: opacity 0.2s ease, height 0.2s ease-in-out;
`;

class Series extends Component {
  constructor(props) {
    super(props);
    this.addSerie = this.addSerie.bind(this);
    this.deleteSerie = this.deleteSerie.bind(this);
    this.loadDb = this.loadDb.bind(this);
    this.updateSerieTitle = this.updateSerieTitle.bind(this);
    this.addPhotos = this.addPhotos.bind(this);
  }

  state = {
    series: [],
    infos: [],
    picture: true,
    editorsState: [],
    infoModified: [],
    reset: false,
    video: [],
    uploading: false,
    uploadError: false
  };
  async componentDidMount() {
    this.loadDb();
  }

  async loadDb() {
    let seriesArr = [];
    this.setState({
      seriis: [],
      editorsState: [],
      isModified: [],
      infos: [],
      video: [],
      uploading: "",
      uploadError: false
    });

    try {
      const series = await this.props.strapi.getEntries("series");
      // const photos = await this.props.strapi.getEntries("photos");
      const infos = await this.props.strapi.getEntries("infos");
      // const videos = await this.props.strapi.getEntries("videos");
      // let infos = informations.filter(item => item.title !== "home-video");
      this.setState({
        series: series
      });
      // console.log(series);
      // series.forEach(serieItem => {
      //   let serie = {};
      //   serie.id = serieItem.id;
      //   serie.title = serieItem.title;
      //   serie.photos = [];
      //   serie.videos = [];
      //   photos.filter(photoItem => {
      //     // console.log(photoItem);
      //     if (photoItem.serie.id == serieItem.id) {
      //       serie.photos.push(photoItem);
      //     }
      //   });
      //   videos.filter(videoItem => {
      //     if (videoItem.serie.id == serieItem.id) {
      //       serie.videos.push(videoItem);
      //     }
      //   });
      //   seriesArr.push(serie);
      //   this.setState({
      //     series: seriesArr
      //   });
      // });
      this.resetEditorsState(infos);
      this.setState({
        infos: infos
      });
    } catch (err) {
      console.log(err);
    }
  }

  resetEditorsState = arr => {
    let infosArr = [];
    arr.forEach(item => {
      infosArr.push({ id: item.id, content: item.content });
      this.setState({
        editorsState: infosArr
      });
    });
    this.setState({
      reset: true
    });
    setTimeout(() => {
      this.setState({
        reset: false
      });
    }, 1000);
  };

  onInfosChange = (editorState, i, id) => {
    const newEditorState = [...this.state.editorsState];
    newEditorState[i] = {
      id: id,
      content: stateToMarkdown(editorState.getCurrentContent())
    };
    // console.log({
    //   id: id,
    //   content: stateToMarkdown(editorState.getCurrentContent())
    // });
    let infoModified = [...this.state.infoModified];
    let infoFind = infoModified.findIndex(modId => modId === id);
    // console.log(infoFind);
    if (infoFind === -1) {
      infoModified.push(id);
    }
    this.setState({ editorsState: newEditorState, infoModified });
  };

  async addSerie() {
    let series = [...this.state.series];
    let serie = {
      title: "new_serie"
    };
    // console.log("before: ", series);
    try {
      await this.props.strapi.createEntry("series", serie);
      series.push(serie);
      this.setState({
        series
      });

      this.loadDb();
    } catch (err) {
      console.log(err);
    }
  }

  async updateSerieTitle(newTitle, id) {
    try {
      await this.props.strapi.updateEntry("series", id, { title: newTitle });
      this.loadDb();
    } catch (err) {
      console.log(err);
    }
  }

  async updateInfo(content, id) {
    try {
      await this.props.strapi.updateEntry("infos", id, { content: content });
      this.loadDb();
    } catch (err) {
      console.error(err);
    }
  }

  updateInfos() {
    this.state.infoModified.forEach(item => {
      let infoModified = this.state.editorsState.find(
        infos => infos.id === item
      );
      this.updateInfo(infoModified.content, infoModified.id);
      // console.log(infoModified);
    });
  }

  async deleteSerie(id) {
    try {
      let count = 0;
      await this.props.strapi
        .getEntry("series", id)
        .then(res => {
          // console.log(res);
          res.photos.forEach(item => {
            this.props.strapi
              .deleteEntry("photos", item.id)
              .then(res => (count += 1));
          });
        })
        .then(res => {
          this.props.strapi.deleteEntry("series", id).then(() => {
            this.loadDb();
          });
        });
    } catch (err) {
      console.log(err);
    }
  }

  // async addPhotos(files, serieId) {
  //   console.log(files, serieId);
  //   try {
  //     let count = 0;
  //     await files.forEach(item => {
  //       const formData = new FormData();
  //       formData.append("files", item);
  //       let file = this.props.strapi.upload(formData).then(res => {
  //         let photo = { img: res, serie: { _id: serieId } };
  //         this.props.strapi.createEntry("photos", photo).then(res => {
  //           console.log("after update", res);
  //           count += 1;
  //           if (count === files.length) {
  //             this.loadDb();
  //           }
  //         });
  //       });
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  async addPhotos(files, serieId) {
    try {
      let count = 0;
      this.setState({
        uploading: serieId
      });
      if (!serieId) {
        alert("Une erreur est survenue, veuillez réessayer");
      } else {
        await files.forEach(item => {
          const formData = new FormData();
          // console.log(item);
          formData.append("files", item);
          // console.log(this.props.strapi);
          if (item.type.indexOf("video") !== -1) {
            let file = this.props.strapi.upload(formData).then(res => {
              let video = {
                vid: res,
                serie: { _id: serieId },
                onHome: false
              };
              this.props.strapi.createEntry("videos", video).then(res => {
                // console.log("after update", res);
                count += 1;
                if (count === files.length) {
                  this.loadDb();
                }
                this.setState({
                  uploading: ""
                });
              });
            });
          }
          if (item.type.indexOf("image") !== -1) {
            let file = this.props.strapi.upload(formData).then(res => {
              // console.log("res", res);
              let photo = {
                img: res,
                serie: { _id: serieId },
                onHome: false
              };
              this.props.strapi.createEntry("photos", photo).then(res => {
                // console.log("after update", res);
                count += 1;
                if (count === files.length) {
                  this.loadDb();
                }
                this.setState({
                  uploading: ""
                });
              });
            });
          }
        });
      }
    } catch (err) {
      this.setState({
        uploading: false,
        uploadError: true
      });
      alert(err);
      console.error(err);
    }
  }

  async addVideos(files, serieId) {
    // console.log(files, serieId);
    try {
      let count = 0;
      await files.forEach(item => {
        const formData = new FormData();
        formData.append("files", item);

        // console.log(this.props.strapi);
        let file = this.props.strapi.upload(formData).then(res => {
          let video = { vid: res, serie: { _id: serieId } };
          this.props.strapi.createEntry("videos", video).then(res => {
            // console.log("after update", res);
            count += 1;
            if (count === files.length) {
              this.loadDb();
            }
          });
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    // console.log(this.state);
    return (
      <div
        style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}
      >
        <Header colorMode={this.props.colorMode} history={this.props.history} />
        <MenuWrapper>
          <ExpoType
            style={{ opacity: this.state.picture ? 1 : 0.18 }}
            onClick={() => this.setState({ picture: true })}
            isFilter={this.state.picture}
            colorMode={this.props.colorMode}
          >
            PICTURES{" "}
            <Span
              className="btn-Menu"
              isFilter={this.state.picture}
              colorMode={this.props.colorMode}
            />
          </ExpoType>
          <ExpoType
            style={{ opacity: !this.state.picture ? 1 : 0.18 }}
            onClick={() => this.setState({ picture: false })}
            isFilter={!this.state.picture}
            colorMode={this.props.colorMode}
          >
            INFOS{" "}
            <Span
              className="btn-Menu"
              isFilter={!this.state.picture}
              colorMode={this.props.colorMode}
            />
          </ExpoType>
        </MenuWrapper>
        {this.state.picture ? (
          <SeriesContainer>
            {this.state.series.length !== 0 &&
              this.state.series.map((item, i) => {
                // console.log("serie: ", item);
                return (
                  <Serie
                    data={item}
                    key={"serie-" + i}
                    colorMode={this.props.colorMode}
                    deleteSerie={id => this.deleteSerie(id)}
                    updateSerieTitle={(newTitle, id) =>
                      this.updateSerieTitle(newTitle, id)
                    }
                    history={this.props.history}
                    addPhotos={files => this.addPhotos(files, item.id)}
                    addVideos={files => this.addVideos(files, item.id)}
                    uploading={this.state.uploading === item.id}
                  />
                );
              })}
            <NewSerie colorMode={this.props.colorMode} onClick={this.addSerie}>
              <Plus />{" "}
            </NewSerie>
          </SeriesContainer>
        ) : (
          <div>
            <SeriesContainer style={{ justifyContent: "center" }}>
              {this.state.infos
                .filter(item => item.title !== "home-video")
                .map((item, i) => {
                  return (
                    <Editor
                      colorMode={this.props.colorMode}
                      title={item.title}
                      content={this.state.editorsState[i].content}
                      id={item.id}
                      index={i}
                      reset={this.state.reset}
                      editorState={this.state.editorsState[i].content}
                      onChange={md => this.onInfosChange(md, i, item.id)}
                    />
                  );
                })}
              <div>
                <Video
                  data={this.state.infos.find(
                    item => item.title === "home-video"
                  )}
                  strapi={this.props.strapi}
                  colorMode={this.props.colorMode}
                  loadDb={this.loadDb}
                />
              </div>
            </SeriesContainer>
            <SeriesContainer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={() => this.updateInfos()}
                colorMode={this.props.colorMode}
              >
                SAVE
              </Button>
              <Button
                onClick={() => this.resetEditorsState(this.state.infos)}
                colorMode={this.props.colorMode}
                light
              >
                CANCEL
              </Button>{" "}
            </SeriesContainer>
          </div>
        )}
      </div>
    );
  }
}

export default SecurePage(Series);
