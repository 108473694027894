import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import cloudinary from "cloudinary-core";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
const cl = new cloudinary.Cloudinary({ cloud_name: "escourbiac" });

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 99999999;
  position: relative;
  overflow: hidden;
`;

const Title = styled.h1`
  font-family: "Interstate";
  font-size: 10vw;
  text-align: center;
  @media (max-width: 960px) {
    font-size: 15vw;
  }
`;

const A = styled.a`
  margin-top: 50px;
  font-family: "Interstate";
  font-size: 40px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: 90px;
  left: 0;
  color: #000;
  margin: 0 auto;
  font-weight: lighter;
  &::after {
    content: "";
    width: 0;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto;
    transition: width 0.2s ease-out;
  }
  &:hover::after {
    width: 50%;
  }
`;

const Button = styled(Link)`
  margin-top: 50px;
  font-family: "Interstate";
  font-size: 40px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: 90px;
  left: 0;
  margin: 0 auto;
  font-weight: lighter;
  &::after {
    content: "";
    width: 0;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto;
    transition: width 0.2s ease-out;
  }
  &:hover::after {
    width: 50%;
  }
`;

const Video = styled.div`
  display: block;
  width: 100vw;
  height: 100%;
  position: absolute;
  /* display: inline-block; */
  /* max-height: 70%; */
  @media (max-width: 600px) {
    top: 0;
  }
  & video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1.5);
    max-width: 90vw;
    top: 0;
    bottom: 0;
    margin: auto 0;
    /* min-height: 100vh; */
    height: 90vh;
  }
  & img {
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transform: translateX(-50%) scale(1.5);
    width: auto;
    height: auto;
    max-width: 90vw;
    /* min-height: 100vh; */
    max-height: 90vh;
  }
`;

var isSafari;

class Landing extends Component {
  state = {
    loaded: false
  };
  componentDidMount() {
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // isSafari = true;
  }

  loadVideo = () => {
    const { video } = this.props;
    if (video) {
      if (isSafari) {
        let wrapper = document.getElementById("videoHome");
        let videoDom = document.createElement("img");
        // let wStyle = {
        //   ,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat"
        // };
        videoDom.src = cl.video_url(video.vid.public_id, {
          format: "gif",
          videoSampling: "40",
          delay: "200",
          width: Math.round(2000),
          effect: "loop",
          crop: "scale"
        });
        // wrapper.style = wStyle;
        videoDom.addEventListener("load", e => {
          if (!this.state.videoLoaded) {
            this.setState({ loaded: true });
            setTimeout(() => wrapper.appendChild(videoDom), 1000);
          }
        });
      } else {
        let wrapper = document.getElementById("videoHome");
        let videoDom = document.createElement("video");
        let source = document.createElement("source");
        source.src = cl.video_url(video.vid.public_id, {
          format: "mp4",
          width: Math.round(window.innerWidth)
        });
        source.mime = video.vid.mime;
        videoDom.appendChild(source);
        videoDom.autoplay = true;
        videoDom.muted = true;
        videoDom.loop = true;
        videoDom.playsinline = true;

        videoDom.addEventListener("canplay", e => {
          if (!this.state.videoLoaded) {
            this.setState({ loaded: true });
            setTimeout(() => wrapper.appendChild(videoDom), 1000);
            if (e.target.play) {
              console.log(videoDom.play);
              e.target.play();
            }
          }
        });
      }

      // videoDom.canplay = e => {
      //   wrapper.appendChild(videoDom);
      //   this.setState({ loaded: true });
      // };
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.video === undefined && this.props.video !== undefined) {
      this.loadVideo();
    }
    if (this.state.loaded === true && prevState.loaded === false) {
    }
  }

  render() {
    const { textColor, bgColor } = this.props.colorMode;
    const { video } = this.props;
    console.log(this.props);
    return (
      <Wrapper
        style={{
          background: "#fff",
          color: "#000"
        }}
      >
        <Video
          id="videoHome"
          // style={{
          //   background:
          //     isSafari &&
          //     video &&
          //     cl.video_url(video.vid.public_id, {
          //       format: "gif",
          //       videoSampling: "40",
          //       delay: "200",
          //       width: Math.round(1400),
          //       effect: "loop",
          //       crop: "scale"
          //     })
          // }}
        >
          {/* <video
            playsInline
            autoplay
            muted
            loop
            onCanPlay={e => e.target.play()}
          >
            <source src="/assets/SIMON ESCOURBIAC_WORK 2019_V01.mp4" />
          </video> */}
        </Video>
        {!this.state.loaded && (
          <Title>
            SIMON <br /> ESCOURBIAC
          </Title>
        )}
        <A href="https://75.tv/talent/simon-escourbiac/">ENTER</A>
        {/* <Button to="/all" style={{ color: "#000" }} textColor={textColor}>
          ENTER
        </Button> */}
      </Wrapper>
    );
  }
}

export default Landing;
