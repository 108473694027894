import React, { Component } from "react";
import styled from "styled-components";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
import "gsap/src/uncompressed/plugins/ScrollToPlugin";
import { withRouter } from "react-router-dom";
import {
  disablePageScroll,
  enablePageScroll,
  addFillGapSelector
} from "scroll-lock";
import Markdown from "react-markdown";

const Plus = props => (
  <svg width={15} height={14} {...props}>
    <g
      stroke={props.colorMode.textColor}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M7.5 13V1.5M1.5 7h12" />
    </g>
  </svg>
);

const lineEq = (y2, y1, x2, x1, currentVal) => {
  var m = (y2 - y1) / (x2 - x1),
    b = y1 - m * x1;
  return m * currentVal + b;
};

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.90)"
    );
  }
  return hex;
}

const CloseWrapper = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

const Left = styled.div`
  position: absolute;
  left: 90px;
  width: calc(50vw - 90px);
  /* top: 200px; */
  /* top: 250px; */
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 550px;
  @media (max-width: 960px) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 90%;
    margin-left: 5%;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media (max-height: 750px) and (min-width: 960px) {
    top: 100px;
    margin: 0;
  }
`;
const Right = styled.div`
  width: calc(50vw - 150px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  right: 150px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 710px;

  @media (max-width: 960px) {
    position: relative;
    width: 90%;
    margin-left: 5%;
    right: 0;
    justify-content: space-between;
  }
  @media (max-height: 915px) and (min-width: 960px) {
    top: 100px;
    margin: 0;
  }
`;

const Title = styled.div`
  font-family: "Interstate";
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 23px 0;
`;

const Text = styled.div`
  font-family: "Interstate";
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 14px;
  text-align: left;
  line-height: 17px;
  & p  {
    margin: 5px 0;
  }
`;

const Bloc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 90%;
  @media (max-width: 960px) {
    &.description {
      max-width: 90%;
    }
  }
`;

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  z-index: 100000;
  background: #bb6767;
  left: 0;
  opacity: 0;
  top: 100vh;

  & > img {
    position: absolute;
    top: 0vh;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  & * {
    color: ${({ colorMode }) => colorMode.textColor};
  }
  @media (max-width: 960px) {
    overflow-y: scroll;
  }
`;
class ImageModal extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.easeIn();
    let photoWrapper = document.getElementsByClassName(".photos-wrapper");
    // disablePageScroll(photoWrapper);
    // addFillGapSelector();
  }

  easeOut = cb => {
    let wrapperOutTimeline = new TimelineLite();
    wrapperOutTimeline
      .to(document.getElementById("modal"), 0.2, {
        ease: "Expo.easeout",
        autoAlpha: 0
      })
      .to(document.getElementById("modal"), 0.2, {
        ease: "Expo.easein",
        y: this.props.windowDimensions.windowHeight * 2 + "px",
        zIndex: 100,
        onComplete: cb
      });
  };

  easeIn = () => {
    let wrapperInTimeline = new TimelineLite();

    wrapperInTimeline
      .set(document.getElementById("modal"), {
        top: 0,
        y: 0,
        zIndex: 10000
        // backgroundColor: bgColor
      })
      .to(document.getElementById("modal"), 0.4, {
        ease: "Expo.easeout",
        autoAlpha: 1
      });
  };

  componentWillUnmount() {}

  render() {
    const { match, location, history, informations } = this.props;
    const collab =
      (informations.length > 0 &&
        informations.find(item => item.title === "collaboration")) ||
      null;
    const thanks =
      (informations.length > 0 &&
        informations.find(item => item.title === "special thanks")) ||
      null;
    const set_design =
      (informations.length > 0 &&
        informations.find(item => item.title === "set design")) ||
      null;
    const prod =
      (informations.length > 0 &&
        informations.find(item => item.title === "post production")) ||
      null;
    return (
      <ModalWrapper
        id={"modal"}
        colorMode={this.props.colorMode}
        style={{
          background:
            hexToRgbA(this.props.colorMode.bgColor) ||
            this.props.colorMode.bgColor
        }}
        tabIndex="-1"
        ref={this.modal}
      >
        <div style={{ position: "relative", width: "100%", minHeight: "100%" }}>
          <CloseWrapper>
            <Plus
              onClick={() => this.easeOut(() => history.goBack())}
              colorMode={this.props.colorMode}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 50,
                margin: "auto 0",
                transform: "scale(2) rotate(45deg)",
                cursor: "pointer"
              }}
            />
          </CloseWrapper>
          <Left>
            <Bloc className="description">
              <Text style={{ color: this.props.colorMode.textColor }}>
                <p>
                  Known for his lighting skills and his perception of colours,{" "}
                  <br />
                  Simon Escourbiac has established a reputation as one of <br />
                  the world’s leading photographers of texture and still life.
                </p>
                <p>His approach is modern, minimalist and sensitive.</p>
                <p>
                  During his shoots he captures the “living moment“ of still
                  life objects.
                </p>
                <p>
                  His advertising clients include Chanel, Dior, Dolce & Gabbana,
                  <br />
                  François Louis Vuitton, Georgio Armani, Givenchy, Lancôme,
                  Moët & Chandon, L’Oréal, Remy Martin.
                </p>
                <p>
                  His work is currently published in Narcisse Magazine,
                  Intersection, SoChic, Citizen K, Tush Magazine, l’Optimum,
                  Sport & Style, L’Express Style.
                </p>
              </Text>
            </Bloc>
            <Bloc>
              <Title>Contact</Title>
              <Text style={{ color: this.props.colorMode.textColor }}>
                Simon ESCOURBIAC
              </Text>
              <Text style={{ color: this.props.colorMode.textColor }}>
                +33(0)660389723
              </Text>
              <a href="mailto:simon.escourbiac@gmail.com" target="__blank">
                <Text style={{ color: this.props.colorMode.textColor }}>
                  simon.escourbiac@gmail.com
                </Text>
              </a>
              <a
                href="https://www.instagram.com/simon_escourbiac/?hl=fr"
                target="__blank"
              >
                <Text style={{ color: this.props.colorMode.textColor }}>
                  @simon_escourbiac
                </Text>
              </a>
            </Bloc>
            <Bloc>
              {/* <Title>Represented by</Title> */}
              {/* <Text style={{color: this.props.colorMode.textColor}}>Sylvie Etchemaite</Text>
              <Text style={{color: this.props.colorMode.textColor}}>+33(0)612960669</Text>
              <a href="mailto:etchemaite@icloud.com">
                <Text style={{color: this.props.colorMode.textColor}}>etchemaite@icloud.com </Text>
              </a> */}
            </Bloc>
          </Left>
          <Right>
            <Bloc>
              <Title>{collab && collab.title}</Title>
              <Text style={{ color: this.props.colorMode.textColor }}>
                <Markdown source={collab && collab.content} />
              </Text>
              <Title>{thanks && thanks.title}</Title>
              <Text style={{ color: this.props.colorMode.textColor }}>
                <Markdown source={thanks && thanks.content} />
              </Text>
            </Bloc>
            <Bloc>
              <Title>credits</Title>
              <Title style={{ marginTop: 0, fontSize: 15 }}>
                {set_design && set_design.title}
              </Title>
              <Text style={{ color: this.props.colorMode.textColor }}>
                <Markdown source={set_design && set_design.content} />
              </Text>
              <Title style={{ marginTop: 10, fontSize: 15 }}>
                {prod && prod.title}
              </Title>
              <Text style={{ color: this.props.colorMode.textColor }}>
                <Markdown source={prod && prod.content} />
              </Text>
            </Bloc>
          </Right>
        </div>
      </ModalWrapper>
    );
  }
}

export default withRouter(ImageModal);
