import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import SecurePage from "../SecurePage";
import DefaultPage from "../DefaultPage";
import Photo from "./Photo";
import Video from "./Video";
import Header from "./Header";

const Plus = props => (
  <svg width={83} height={82} {...props}>
    <g stroke="#979797" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M41.5.5v80.572M82 41H1.428" />
    </g>
  </svg>
);

const NewPhoto = styled.div`
  width: 275px;
  height: 208px;
  margin: 23px;
  position: relative;
  background: rgba(124, 124, 124, 0);
  transition: background 0.5s ease;
  cursor: pointer;
  &:hover {
    background: rgba(124, 124, 124, 0.1);
  }
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999999999;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ uploading }) => (uploading ? 1 : 0)};
  pointer-events: ${({ uploading }) => (uploading ? "auto" : "none")};
  transition: opacity 0.2s ease-in;
`;

const loaderAnim = keyframes`
  0%{
    transform: scale(0.3)
  }
  50%{
    transform: scale(0.6)
  }
  100%{
    transform: scale(0.3)
  }
`;

const Loader = styled.div`
  width: 150px;
  height: 150px;
  transform: scale(0.3);
  border-radius: 100%;
  background: #000;
  animation: ${loaderAnim} 2s ease-in-out infinite;
`;

const AddPhotos = styled.div`
  z-index: 99999;
  width: 100%;
  height: 100vh;
  background: #fff;
  opacity: ${({ drag }) => (drag ? ".9" : "0")};
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.5s ease;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const PhotoContainer = styled.div`
  margin: 0 130px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Image = styled.div`
  width: 100%;
  height: 208px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(231, 231, 231);
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: url("/assets/icons/serie.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    right: 5px;
    top: 5px;
  }
`;

const ImgInput = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.5s ease;
  opacity: ${({ drag }) => (drag ? 1 : 0)};
  &::after {
    content: "";
    width: 82px;
    height: 82px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-image: url("/assets/icons/plus.svg");
  }
`;

class Photos extends Component {
  state = {
    photos: [],
    videos: [],
    tags: [],
    serie: {},
    drag: false,
    uploading: false,
    uploadError: false
  };

  dropRef = React.createRef();
  updateTags = this.updateTags.bind(this);

  async componentDidMount() {
    const { match, history, location } = this.props;
    this.loadDb(match.params.serieId);
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  async loadDb(serieId) {
    let photosArr = [];
    let videosArr = [];
    try {
      const tags = await this.props.strapi.getEntries("tags");
      // const photos = await this.props.strapi.getEntries("photos");
      const serie = await this.props.strapi.getEntry("series", serieId);
      // const videos = await this.props.strapi.getEntries("videos");
      // console.log(tags);
      // photos.sort((a, b) => a.id - b.id);
      // console.log("tags= ", serie);
      // // photosArr.push([...serie.photos]);
      // photos.filter(photoItem => {
      //   console.log(photoItem.serie.id);
      //   if (photoItem.serie.id == serieId) {
      //     photosArr.push(photoItem);
      //   }
      // });

      // videos.filter(videoItem => {
      //   if (videoItem.serie.id == serieId) {
      //     videosArr.push(videoItem);
      //   }
      // });

      this.setState({
        photos: serie.photos,
        tags: tags,
        serie: serie,
        videos: serie.videos
      });
      // console.log("series: ", photosArr);
    } catch (err) {
      console.log(err);
    }
  }

  async deletePhoto(id) {
    try {
      await this.props.strapi.deleteEntry("photos", id).then(res => {
        this.loadDb(this.props.match.params.serieId);
      });
    } catch (err) {
      alert(err);
      console.log(err);
    }
  }

  async addPhotos(files) {
    try {
      let count = 0;
      // console.log(files);
      this.setState({
        uploading: true
      });
      await files.forEach(item => {
        const formData = new FormData();
        // console.log(item);
        formData.append("files", item);
        console.log(item);
        if (item.type.indexOf("video") !== -1) {
          let file = this.props.strapi.upload(formData).then(res => {
            let video = {
              vid: res,
              serie: { _id: this.props.match.params.serieId },
              onHome: false
            };
            this.props.strapi.createEntry("videos", video).then(res => {
              // console.log("after update", res);
              count += 1;
              if (count === files.length) {
                this.loadDb(this.props.match.params.serieId);
              }
              this.setState({
                uploading: false
              });
            });
          });
        }
        if (item.type.indexOf("image") !== -1) {
          let file = this.props.strapi.upload(formData).then(res => {
            // console.log("res", res);
            let photo = {
              img: res,
              serie: { _id: this.props.match.params.serieId },
              onHome: false
            };
            this.props.strapi.createEntry("photos", photo).then(res => {
              // console.log("after update", res);
              count += 1;
              if (count === files.length) {
                this.loadDb(this.props.match.params.serieId);
              }
              this.setState({
                uploading: false
              });
            });
          });
        }
      });
    } catch (err) {
      this.setState({
        uploading: false,
        uploadError: true
      });
      alert(err);
      console.error(err);
    }
  }

  inputAddPhotos = e => {
    if (e.target.files && Object.values(e.target.files).length > 0) {
      this.addPhotos(Object.values(e.target.files));
    }
  };

  async updateOnHome(photoId, bool, type) {
    // console.log(photoId, bool, type);
    try {
      await this.props.strapi
        .updateEntry(type, String(photoId), {
          onHome: Boolean(bool)
        })
        .then(res => {
          // console.log("res", res);
          this.loadDb(this.props.match.params.serieId);
        });
    } catch (err) {
      console.error(err);
    }
  }

  async updateTitle(photoId, title, type) {
    // console.log(photoId, bool, type);
    try {
      await this.props.strapi
        .updateEntry(type, String(photoId), {
          title: String(title)
        })
        .then(res => {
          console.log("res", res);
          this.loadDb(this.props.match.params.serieId);
        });
    } catch (err) {
      console.error(err);
    }
  }

  async updateTags(tagsIdarr, photoId, type) {
    try {
      // console.log(photoId, tagsIdarr);
      let photo = await this.props.strapi
        .updateEntry(type, String(photoId), {
          tags: tagsIdarr
        })
        .then(res => {
          // console.log("tags res", res);
          this.loadDb(this.props.match.params.serieId);
        });
    } catch (err) {
      console.error(err);
    }
  }

  async addTag(title, photo, type) {
    await this.props.strapi.createEntry("tags", { title: title }).then(res => {
      console.log(res);
      const tagsIdarr = [...photo.tags, res];
      this.updateTags(tagsIdarr, photo._id, type);
    });
  }

  async deleteTag(tagId) {
    try {
      await this.props.strapi
        .deleteEntry("tags", tagId)
        .then(res => this.loadDb(this.props.match.params.serieId));
    } catch (err) {
      // alert(err);
      console.error(err);
    }
  }

  dragCounter = 0;

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // console.log(Object.values(e.dataTransfer.files));

      this.addPhotos(Object.values(e.dataTransfer.files));
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  render() {
    const { history, match, location } = this.props;
    // console.log("series Props", this.state);
    return (
      <div
        ref={this.dropRef}
        style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}
      >
        <Header
          colorMode={this.props.colorMode}
          serie={this.state.serie}
          history={this.props.history}
        />
        <PhotoContainer>
          {this.state.photos.map((item, i) => (
            <Photo
              data={item}
              key={"admin-photo-" + i}
              colorMode={this.props.colorMode}
              deletePhoto={id => this.deletePhoto(id)}
              addPhotos={files => this.addPhotos(files, item.id)}
              updateOnHome={bool => this.updateOnHome(item.id, bool, "photos")}
              addTag={title => this.addTag(title, item, "photos")}
              deleteTag={tagId => this.deleteTag(tagId)}
              updateTitle={title => this.updateTitle(item.id, title, "photos")}
              tags={this.state.tags}
              updateTags={tagsIdarr =>
                this.updateTags(tagsIdarr, item.id, "photos")
              }
              serie={this.state.serie}
            />
          ))}
          {this.state.videos.map((item, i) => (
            <Video
              data={item}
              key={"admin-photo-" + i}
              colorMode={this.props.colorMode}
              deletePhoto={id => this.deletePhoto(id)}
              updateOnHome={bool => this.updateOnHome(item.id, bool, "videos")}
              updateTitle={title => this.updateTitle(item.id, title, "videos")}
              addTag={title => this.addTag(title, item, "videos")}
              addPhotos={files => this.addPhotos(files, item.id)}
              deleteTag={tagId => this.deleteTag(tagId)}
              tags={this.state.tags}
              updateTags={tagsIdarr =>
                this.updateTags(tagsIdarr, item.id, "videos")
              }
              serie={this.state.serie}
            />
          ))}
          <NewPhoto
            colorMode={this.props.colorMode}
            onClick={() => this.imgInput.click()}
          >
            <Plus
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                margin: "auto"
              }}
            />
            <input
              ref={div => (this.imgInput = div)}
              type="file"
              accept=".jpg, .jpeg, .png, .mp4"
              onChange={this.inputAddPhotos}
              multiple
              style={{
                opacity: 0,
                width: "0.01px",
                height: "0.01px",
                display: "none"
              }}
            />
          </NewPhoto>
        </PhotoContainer>
        <AddPhotos drag={this.state.drag}>
          <Plus
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "auto"
            }}
          />
        </AddPhotos>
        <UploadWrapper uploading={this.state.uploading}>
          <Loader colorMode={this.props.colorMode} />
        </UploadWrapper>
      </div>
    );
  }
}

export default SecurePage(Photos);
