import React, { Component } from "react";
import styled from "styled-components";
import { unsetToken } from "../lib/auth";

const Wrapper = styled.div`
  width: 100vw;
  height: 150px;
  position: relative;
`;

const Title = styled.div`
  position: absolute;
  font-family: "SFProText-Bold";
  color: ${({ colorMode }) => colorMode.textColor};
  left: 54px;
  top: 41px;
`;

const Logout = styled.div`
  position: absolute;
  font-family: "SFProText-Bold";
  color: ${({ colorMode }) => colorMode.textColor};
  right: 54px;
  top: 41px;
  cursor: pointer;
`;

class Header extends Component {
  render() {
    return (
      <Wrapper>
        <Title colorMode={this.props.colorMode}>SIMON ESCOURBIAC</Title>
        <Logout
          colorMode={this.props.colorMode}
          onClick={() => unsetToken(this.props.history)}
        >
          LOG OUT
        </Logout>
      </Wrapper>
    );
  }
}

export default Header;
