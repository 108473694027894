import React, { Component } from "react";
import styled from "styled-components";
import ImageLoader from "react-load-image";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
import "gsap/src/uncompressed/plugins/ScrollToPlugin";
// import { lazyload } from "react-lazyload";
import { withRouter, Link } from "react-router-dom";
import cloudinary from "cloudinary-core";

const Img = styled.img`
  width: 100%;
  height: auto;
  margin: 5px;
  z-index: 100;
`;

const Loader = styled.div`
  /* background: rgba(208, 208, 208, 0.3); */
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const lineEq = (y2, y1, x2, x1, currentVal) => {
  // y = mx + b
  var m = (y2 - y1) / (x2 - x1),
    b = y1 - m * x1;
  return m * currentVal + b;
};
const Wrapper = styled.div`
  /* & a {
    display: flex;
    align-items: center;
  }
  & a:before,
  a:after {
    flex: 1;
    content: "";
    height: 1px;
    background: black;
  } */
`;

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  left: 0;
  opacity: 0;
  top: 100vh;
  & > img {
    position: absolute;
    top: 0vh;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;

let ImgNearby;

// @lazyload({
//   height: 200,
//   once: true,
//   offset: 200
// })
class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: Math.random() * 250 + 150,
      width: 0,
      defaultDim: { height: 0, width: 0 },
      isModalToggled: false
    };
    this.wrapper = null;
    this.img = null;
  }

  setImgWidth = () => {
    let width = Math.round(
      Math.round(
        this.props.windowDimensions.windowWidth / this.props.nbrColumn - 25
      ) * 1.1
    );
    if (width > 500) {
      return width;
    } else {
      return 500;
    }
  };

  async componentDidMount() {
    this.wrapper &&
      TweenMax.to(this.wrapper, 0.5, {
        ease: "Expo.easeout",
        backgroundColor: "rgba(191, 188, 188, 0.3)"
      });
    let img = document.createElement("img");
    img.alt = this.props.title;
    const cloudinaryCore = new cloudinary.Cloudinary({
      cloud_name: "escourbiac"
    });

    try {
      // var input = this.props.photo.vid.name;
      // var output = input.substr(0, input.lastIndexOf(".")) || input;
      img.src = await cloudinaryCore.video_url(this.props.photo.vid.public_id, {
        format: "gif",
        videoSampling: "40",
        delay: "200",
        width: this.setImgWidth(),
        effect: "loop",
        crop: "scale"
      });
      // console.log(img.src);
      let poll = setInterval(() => {
        if (img.naturalHeight) {
          clearInterval(poll);
          this.setState({
            width:
              this.props.device === "mobile"
                ? this.props.windowDimensions.windowWidth /
                    this.props.nbrColumn -
                  5
                : this.props.windowDimensions.windowWidth /
                    this.props.nbrColumn -
                  25,
            height:
              ((this.props.device === "mobile"
                ? this.props.windowDimensions.windowWidth /
                    this.props.nbrColumn -
                  5
                : this.props.windowDimensions.windowWidth /
                    this.props.nbrColumn -
                  25) *
                img.naturalHeight) /
              img.naturalWidth
          });
          this.setState({
            defaultDim: {
              width: img.naturalWidth,
              height: img.naturalHeight
            }
          });
          img.setAttribute("width", "100%");
          img.setAttribute("height", "100%");
          img.style.opacity = 0;
          img.id = this.props.id + "-image";
          img.className = "img-grid";
          img.style.position = "relative";
          img.style.transform = "scale(1.01)";
        }
      });

      img.onload = () => {
        if (this.wrapper !== null) {
          this.wrapper.appendChild(img);
          setTimeout(() => {
            TweenMax.to(img, 1, {
              ease: "linear",
              autoAlpha: 1
            });
          }, 500);

          const scaleInterval = { from: 1, to: 1.05 };
          const greyInterval = { from: 0, to: 0.7 };
        }
      };
    } catch (err) {
      console.log(err);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.windowDimensions.windowWidth !==
      prevProps.windowDimensions.windowWidth
    ) {
      this.setState({
        width:
          this.props.device === "mobile"
            ? this.props.windowDimensions.windowWidth / this.props.nbrColumn - 5
            : this.props.windowDimensions.windowWidth / this.props.nbrColumn -
              25,
        height:
          ((this.props.device === "mobile"
            ? this.props.windowDimensions.windowWidth / this.props.nbrColumn - 5
            : this.props.windowDimensions.windowWidth / this.props.nbrColumn -
              25) *
            this.state.defaultDim.height) /
          this.state.defaultDim.width
      });
    }
    if (this.state.isModalToggled !== prevState.isModalToggled) {
      this.toggleModal();
    }
  }

  render() {
    const { match, location, history, style } = this.props;
    // console.log(match.url);
    return (
      <Wrapper>
        <Link
          to={`${match.url}/img/${this.props.imgId}`}
          style={{ backgroundColor: this.props.colorMode.bgColor }}
        >
          <div
            onClick={() => console.log(this.props.photo)}
            style={{
              width: this.state.width,
              height: this.state.height,
              zIndex: 100,
              margin: "5px 0",
              transition: "height .5s",
              position: "relative",
              overflow: "hidden",
              cursor: "crosshair",
              ...style
            }}
            tabIndex="0"
            ref={div => (this.wrapper = div)}
            id={this.props.id}
            className={this.props.className + " img-grid-container"}
          />
        </Link>
      </Wrapper>
    );
  }
}

export default withRouter(Video);
