import React, { Component } from "react";
import styled from "styled-components";

const Arrow = props => (
  <svg width={15} height={25} {...props}>
    <path
      d="M14 2L3 12.5 14 23"
      stroke={props.colorMode.textColor}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

const Wrapper = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
`;

const Title = styled.div`
  font-family: "SFProText-Bold";
  font-size: 30px;
  color: ${({ colorMode }) => colorMode.textColor};
  text-align: left;
  text-transform: uppercase;
`;

const Photos = styled.div`
  font-family: "VCROSDMono";
  font-size: 12;
  color: ${({ colorMode }) => colorMode.textColor};
  text-align: left;
  text-transform: uppercase;
  margin-top: 11px;
`;

const Nav = styled.div`
  position: absolute;
  left: 150px;
  top: 100px;
  cursor: pointer;
`;

class Header extends Component {
  render() {
    // console.log(this.props.serie);
    return (
      <Wrapper>
        <Nav onClick={() => this.props.history.goBack()}>
          <Arrow
            colorMode={this.props.colorMode}
            style={{
              position: "absolute",
              left: "-40px",
              top: "6px"
            }}
          />
          <Title colorMode={this.props.colorMode}>
            {this.props.serie.title}
          </Title>
          <div style={{ display: "flex" }}>
            <Photos colorMode={this.props.colorMode}>
              {this.props.serie.photos &&
                this.props.serie.photos.length + " PHOTOS"}
            </Photos>
            <Photos
              colorMode={this.props.colorMode}
              style={{
                paddingLeft: 15
              }}
            >
              {this.props.serie.videos &&
                this.props.serie.videos.length + " VIDEOS"}
            </Photos>
          </div>
        </Nav>
      </Wrapper>
    );
  }
}

export default Header;
