import React, { Component } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  BrowserRouter
} from "react-router-dom";
import Strapi from "strapi-sdk-javascript/build/main";

import "./App.css";
// import "react-router-modal/css/react-router-modal.css";

import ImageContainer from "./components/ImageContainer";
import Home from "./components/ImageContainer/Home";
import MenuTest from "./components/Menu";
import Landing from "./components/Landing";
// import Menu from "./components/Menu";

import { TweenMax } from "gsap/TweenMax";
import ImageModal from "./components/Modal";
import TagsModal from "./components/Modal/TagsModal";
import Admin from "./components/Admin";
import { ModalRoute, ModalContainer } from "react-router-modal";
import InfosModal from "./components/Modal/InfosModal";

const Menu = withRouter(MenuTest);

const strapi = new Strapi("https://api.simon-escourbiac.com");
// const strapi = new Strapi("http://localhost:1337");

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

class App extends Component {
  state = {
    filter: "",
    windowWidth: 0,
    windowHeight: 0,
    device: "desktop",
    colorMode: { bgColor: "#fff", textColor: "#000" },
    home: [],
    photos: [],
    tags: [],
    selectedTag: {},
    strapiPhotos: [],
    informations: []
  };
  handleFilterClick = (filter, history) => {
    if (this.state.filter !== filter) {
      if (window.scrollY) {
        window.scrollTo({ top: 0 });
        TweenMax.to(
          document.getElementsByClassName("img-grid-container"),
          0.2,
          {
            ease: "Expo.easein",
            backgroundColor: "rgba(0, 0, 0, 0)"
          }
        );
        TweenMax.to(document.getElementsByClassName("img-grid"), 0.2, {
          ease: "linear",
          opacity: 0,
          onComplete: this.setFilter,
          onCompleteParams: [filter, history]
        });
      } else {
        TweenMax.to(document.getElementsByClassName("img-grid"), 0.2, {
          ease: "Expo.easein",
          opacity: 0,
          onComplete: this.setFilter,
          onCompleteParams: [filter, history]
        });
        TweenMax.to(
          document.getElementsByClassName("img-grid-container"),
          0.2,
          {
            ease: "Expo.easein",
            backgroundColor: "rgba(0, 0, 0, 0)"
          }
        );
      }
    }
  };

  setFilter = (filter, history) => {
    this.setState({
      filter: filter
    });
    history.push("/" + filter);
  };
  setDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };
  setDevice = () => {
    if (this.state.windowWidth < 961 && this.state.device !== "mobile") {
      this.setState({
        device: "mobile"
      });
    } else if (
      this.state.windowWidth >= 761 &&
      this.state.device !== "desktop"
    ) {
      this.setState({
        device: "desktop"
      });
    }
  };

  setColorMode = () => {
    let ran = Math.round(Math.random());
    switch (ran) {
      case 0:
        this.setState({ colorMode: { textColor: "#fff", bgColor: "#000" } });
        document.getElementsByTagName("html")[0].style.backgroundColor = "#000";
        break;
      case 1:
        this.setState({ colorMode: { textColor: "#000", bgColor: "#fff" } });
        document.getElementsByTagName("html")[0].style.backgroundColor = "#fff";
        break;
      default:
        this.setState({ colorMode: { textColor: "#000", bgColor: "#fff" } });
        document.getElementsByTagName("html")[0].style.backgroundColor = "#000";
        break;
    }
  };

  async componentDidMount() {
    this.setDimensions();
    this.setColorMode();
    window.onresize = e => {
      this.setDimensions();
    };
    this.setState({
      loading: true
    });
    try {
      const photos = await strapi.getEntries("photos", { _limit: 10000 });
      const videos = await strapi.getEntries("videos");
      const series = await strapi.getEntries("series");
      const tags = await strapi.getEntries("tags");
      // console.log(photos);
      const photosFiltered = tags.map(tag => {
        tag.photos = photos.filter(item => {
          return item.tags.findIndex(a => a.id === tag.id) !== -1;
        });
        return tag;
      });
      const home = [
        ...photos.filter(item => item.onHome === true),
        ...videos.filter(item => item.onHome === true)
      ];
      const informations = await strapi.getEntries("infos");
      this.setState({
        strapiPhotos: shuffle([...photos, ...videos]),
        series: series,
        informations: informations,
        loading: false,
        tags: tags
      });
    } catch (err) {
      console.error(err);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.windowWidth !== prevState.windowWidth) {
      this.setDevice();
    }
  }
  render() {
    console.log("pathname", this.state);
    const { match } = this.props;
    return (
      <Switch>
        <div
          className="App"
          style={{
            background: this.state.colorMode.bgColor,
            height: "100vh",
            overflowY: "hidden"
          }}
        >
          <Menu
            filter={this.state.filter}
            handleFilterClick={(filter, history) =>
              this.handleFilterClick(filter, history)
            }
            device={this.state.device}
            colorMode={this.state.colorMode}
            informations={this.state.informations}
          />

          <Route
            exact
            path="/"
            render={props => (
              <Landing
                colorMode={this.state.colorMode}
                video={this.state.informations.find(
                  info => info.title === "home-video"
                )}
              />
            )}
          />
          <Admin
            windowDimensions={{
              windowWidth: this.state.windowWidth,
              windowHeight: this.state.windowHeight
            }}
            strapi={strapi}
            colorMode={this.state.colorMode}
            informations={this.state.informations}
            device={this.state.device}
            photos={this.state.strapiPhotos}
            {...this.props}
          />
          {/* )}
          /> */}
          <Route
            path="/all"
            render={props => (
              <Home
                windowDimensions={{
                  windowWidth: this.state.windowWidth,
                  windowHeight: this.state.windowHeight
                }}
                colorMode={this.state.colorMode}
                informations={this.state.informations}
                series={this.state.series}
                device={this.state.device}
                filter={this.state.filter}
                tags={this.state.tags}
                // filteredPhotos={this.state.textures}
                filteredPhotos={this.state.strapiPhotos.filter(
                  item => item.onHome === true
                )}
                photos={this.state.strapiPhotos}
                nbrColumn={2}
                {...props}
              />
            )}
          />
          {this.state.tags.length > 0 &&
            this.state.tags.map(tag => (
              <Route
                path={`/${tag.title}`}
                render={props => (
                  <ImageContainer
                    windowDimensions={{
                      windowWidth: this.state.windowWidth,
                      windowHeight: this.state.windowHeight
                    }}
                    colorMode={this.state.colorMode}
                    informations={this.state.informations}
                    series={this.state.series}
                    device={this.state.device}
                    filter={tag.title}
                    tags={this.state.tags}
                    filteredPhotos={this.state.strapiPhotos.filter(item =>
                      item.tags.find(a => a._id === tag._id)
                    )}
                    photos={this.state.strapiPhotos}
                    {...props}
                  />
                )}
              />
            ))}
          <ModalContainer />
        </div>
      </Switch>
    );
  }
}

export default App;
