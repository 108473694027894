import React, { Component } from "react";
import styled from "styled-components";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
import "gsap/src/uncompressed/plugins/ScrollToPlugin";
import { withRouter, Redirect } from "react-router-dom";
import {
  disablePageScroll,
  enablePageScroll,
  addFillGapSelector
} from "scroll-lock";
import cloudinary from "cloudinary-core";
// import LazyLoad from "react-lazyload";

const cl = new cloudinary.Cloudinary({ cloud_name: "escourbiac" });

const lineEq = (y2, y1, x2, x1, currentVal) => {
  // y = mx + b
  var m = (y2 - y1) / (x2 - x1),
    b = y1 - m * x1;
  return m * currentVal + b;
};

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.98)"
    );
  }
  throw new Error("Bad Hex");
}

const SerieArrow = props => (
  <svg width={51} height={8} style={{ marginLeft: "10px" }} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.5 4h48.434" stroke={props.color} strokeLinecap="square" />
      <path fill={props.color} d="M51 4l-6 4V0z" />
    </g>
  </svg>
);

const Plus = props => (
  <svg width={15} height={14} {...props}>
    <g
      stroke={props.colorMode.textColor}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M7.5 13V1.5M1.5 7h12" />
    </g>
  </svg>
);

const Arrow = props => (
  <svg width={23} height={40} {...props}>
    <path
      d="M1 2l19 18L1 38"
      stroke={props.color}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

const ModalWrapper = styled.div`
  min-width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  left: 0;
  opacity: 0;
  top: 100vh;
  overflow: hidden;
  display: flex;
  ${"" /* padding: 0 20vw; */}
  & img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
  }
  & video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
  }
  /* @media (max-width: 900px) {
    & img {
      top: -125px;
    }
    & video {
      top: -125px;
    }
  } */
`;

const ArrowContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 100vh;
  /* @media (max-width: 900px) {
    & svg {
      position: absolute;

      top: 70%;
    }
  } */
`;

const Close = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const ModalImage = styled.img`
  max-width: 70vw;
  max-height: 80%;
  display: block;
  ${"" /* cursor: pointer; */}
  transition: left 0.5s ease-in;
`;

const ModalVideo = styled.video`
  max-width: 70vw;
  max-height: 80%;
  display: block;
  ${"" /* cursor: pointer; */}
  transition: left 0.5s ease-in;
`;

const SerieTitle = styled.div`
  position: absolute;
  right: 50px;
  margin: 0 auto;
  bottom: 150px;
  font-family: "Interstate";
  font-size: 22px;
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
`;

class ImageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: Math.random() * 250 + 150,
      width: 0,
      isModalToggled: true,
      photosDiapo: [],
      photoSelected: {},
      imageIndex: 0,
      imgLoaded: [],
      serieTitle: ""
    };
    this.img = null;
    this.modal = React.createRef();
    this.imageContainer = React.createRef();
  }

  componentDidMount() {
    if (this.props.photos.length) {
      this.filterBySerie();
    }
    this.easeIn(this.props.colorMode.bgColor);
    window.addEventListener("resize", this.placeImgForEach);
  }

  handleEscape = e => {
    if (e.key === "Escape") {
      this.setState({ isModalToggled: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isModalToggled !== prevState.isModalToggled) {
      this.initModal();
    }
    if (this.props.photos !== prevProps.photos) {
      if (this.props.photos.length !== 0) {
        this.filterBySerie();
      }
    }
    if (
      prevState.photosDiapo.length !== this.state.photosDiapo.length &&
      prevState.photosDiapo.length === 0
    ) {
      // console.log("ddd");
      this.initModal();
    }
    if (this.state.imgLoaded !== prevState.imgLoaded) {
      // if (
      //   this.state.imgLoaded.findIndex(i => i === 0) !== -1 &&
      //   this.state.imgLoaded.findIndex(i => i === 1) !== -1
      // ) {
      // let setModalBgColor = hexToRgbA(this.props.colorMode.bgColor);
      this.easeIn(this.props.colorMode.bgColor);
      // }
    }
  }

  componentWillUnmount() {
    // if (ImgNearby) ImgNearby.remove();
    enablePageScroll(".photos-wrapper");
    window.removeEventListener("onkeydown", this.handleEscape);
    window.removeEventListener("resize", this.placeImgForEach);
  }

  filterBySerie = () => {
    const { photos, match, series } = this.props;
    // console.log(photos, match);
    let photoSelected = photos.find(elem => {
      // console.log("elem ", match.params.imgId);
      if (elem.img) {
        return elem.img.id === match.params.imgId;
      } else if (elem.vid) {
        return elem.vid.id === match.params.imgId;
      }
    });
    // console.log("photos Selected", photoSelected);
    let filterPhotos = [photoSelected];
    // console.log("series: ", this.props);
    let serie = {};
    if (photoSelected.serie._id) {
      serie = series.find(el => el._id === photoSelected.serie._id);
    } else {
      serie = series.find(el => el._id === photoSelected.serie);
    }
    filterPhotos.push(
      ...serie.photos.filter(el => el._id !== photoSelected._id)
    );
    filterPhotos.push(
      ...serie.videos.filter(el => el._id !== photoSelected._id)
    );
    // console.log(filterPhotos);
    // photos
    //   .filter(item => {
    //     // console.log(item, photoSelected.serie);
    //     return (
    //       item.serie.id === photoSelected.serie.id &&
    //       item.id !== photoSelected.id
    //     );
    //   })
    //   .forEach(item => filterPhotos.push(item));
    this.setState({
      photosDiapo: filterPhotos,
      photosSelected: photoSelected,
      serieTitle: serie.title
    });
    // console.log(photoSelected, filterPhotos);
  };

  easeOut = (bgColor, cb) => {
    let wrapperOutTimeline = new TimelineLite();
    console.dir(this.modal.current.children);
    TweenMax.to(document.getElementsByClassName("img-modal"), 0.5, {
      ease: "Expo.easeout",
      autoAlpha: 0
    });
    wrapperOutTimeline
      .to(document.getElementById("modal"), 0.2, {
        ease: "Expo.easeout",
        autoAlpha: 0
      })
      .to(document.getElementById("modal"), 0.2, {
        ease: "Expo.easein",
        y: this.props.windowDimensions.windowHeight * 2 + "px",
        zIndex: 100,
        backgroundColor: bgColor,
        onComplete: cb
      });
  };

  easeIn = bgColor => {
    let wrapperInTimeline = new TimelineLite();
    let setModalBgColor = hexToRgbA(this.props.colorMode.bgColor);
    wrapperInTimeline
      .set(document.getElementById("modal"), {
        top: 0,
        y: 0,
        zIndex: 10000,
        backgroundColor: setModalBgColor
      })
      .to(document.getElementById("modal"), 0.4, {
        ease: "Expo.easeout",
        autoAlpha: 1
      });
    // .to(document.getElementsByClassName("img-modal"), 0.5, {
    //   ease: "Expo.easeout",
    //   autoAlpha: 1
    // });
  };

  closeModal = () => {
    // let setModalBgColor = hexToRgbA(this.props.colorMode.bgColor);
    const { history, location } = this.props;
    this.easeOut(() => history.push("/" + location.pathname.split("/")[1]));
  };

  initModal = () => {
    let isOrientationPortait =
      this.props.windowDimensions.windowWidth <
      this.props.windowDimensions.windowHeight;

    let setModalImgSize = () => {
      let size = { width: 0, height: 0 };
      if (isOrientationPortait) {
        size.width = this.props.windowDimensions.windowWidth * 0.9;
        size.height =
          (this.props.windowDimensions.windowWidth * 0.9 * this.state.height) /
          this.state.width;
      } else {
        size.height = this.props.windowDimensions.windowHeight * 0.9;
        size.width =
          (this.props.windowDimensions.windowHeight * 0.9 * this.state.width) /
          this.state.height;
      }
      return size;
    };
    disablePageScroll(".photos-wrapper");
    addFillGapSelector(".photos-wrapper");
  };

  onImageClick = elemIndex => {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let imgModal = document.getElementsByClassName("image-modal");
    // let arr = [...document.getElementsByClassName("image-modal")];
    let arr = [];
    for (var i = 0; i < imgModal.length; i++) {
      arr.push(imgModal[i]);
    }
    let photoSelected = document.getElementById("image-modal-" + elemIndex);
    let nextPhoto = document.getElementById("image-modal-" + (elemIndex + 1));
    let prevPhoto = document.getElementById("image-modal-" + (elemIndex - 1));

    let defaultPos = (window.innerWidth - photoSelected.offsetWidth) / 2;
    let leftPos = prevPhoto
      ? -1 *
          document.getElementById("image-modal-" + (elemIndex - 1))
            .offsetWidth +
        defaultPos / 2
      : 0;
    let rightPos = window.innerWidth - defaultPos / 2;

    if (elemIndex !== this.state.imageIndex) {
      // console.log(defaultPos, leftPos, rightPos);
      arr.forEach((item, i) => {
        if (i === elemIndex && photoSelected.play && !isSafari) {
          photoSelected.play();
        }
        if (prevPhoto && prevPhoto.play) {
          prevPhoto.pause();
        }
        if (nextPhoto && nextPhoto.play) {
          nextPhoto.pause();
        }
        if (i === elemIndex) {
          TweenMax.to(item, 0.5, {
            ease: "Power2.easeInOut",
            x: defaultPos,
            autoAlpha: 1
          });
        } else if (i + 1 === elemIndex) {
          if (prevPhoto) {
            // console.log(elemIndex - i);
            TweenMax.to(item, 0.5, {
              ease: "Power2.easeInOut",
              x: (elemIndex - i) * leftPos,
              autoAlpha: 0.7
            });
          }
        } else if (i - 1 === elemIndex) {
          // console.log(i - elemIndex);
          if (nextPhoto) {
            TweenMax.to(item, 0.5, {
              ease: "Power2.easeInOut",
              x: (i - elemIndex) * rightPos,
              autoAlpha: 0.7
            });
          }
        } else if (i < elemIndex) {
          TweenMax.to(item, 0.5, {
            ease: "Power2.easeInOut",
            x: (elemIndex - i) * leftPos - window.innerWidth / 2,
            autoAlpha: 0.7
          });
        } else if (i > elemIndex) {
          TweenMax.to(item, 0.5, {
            ease: "Power2.easeInOut",
            x: (i - elemIndex) * rightPos + window.innerWidth / 2,
            autoAlpha: 0.7
          });
        }
      });
      this.setState({
        imageIndex: elemIndex
      });
    }
  };

  placeImg = i => {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let photoSelected = document.getElementById("image-modal-" + i);
    let nextPhoto = document.getElementById(
      "image-modal-" + (this.state.imageIndex + 1)
    );
    let prevPhoto = document.getElementById(
      "image-modal-" + (this.state.imageIndex - 1)
    );
    let imgLoaded = [...this.state.imgLoaded];
    let defaultPos = (window.innerWidth - photoSelected.offsetWidth) / 2;
    // console.log("i: ", i, "=", photoSelected.clientWidth);
    let leftPos = prevPhoto ? -1 * prevPhoto.offsetWidth + defaultPos / 2 : 0;
    let rightPos = window.innerWidth - defaultPos / 2;
    imgLoaded.push(i);
    // console.dir(photoSelected);
    // console.log((window.innerWidth - photoSelected.clientWidth) / 2);
    if (i === this.state.imageIndex && photoSelected.play && !isSafari) {
      photoSelected.play();
    }
    if (i === this.state.imageIndex) {
      TweenMax.to(photoSelected, 0.5, {
        ease: "Power2.easeInOut",
        x: defaultPos,
        autoAlpha: 1
      });
    } else if (i + 1 === this.state.imageIndex) {
      if (prevPhoto) {
        // console.log(this.state.imageIndex - i);
        TweenMax.to(photoSelected, 0.5, {
          ease: "Power2.easeInOut",
          x: (this.state.imageIndex - i) * leftPos,
          autoAlpha: 0.7
        });
      }
    } else if (i - 1 === this.state.imageIndex) {
      // console.log(i - this.state.imageIndex);
      if (nextPhoto) {
        TweenMax.to(photoSelected, 0.5, {
          ease: "Power2.easeInOut",
          x: (i - this.state.imageIndex) * rightPos,
          autoAlpha: 0.7
        });
      }
    } else if (i < this.state.imageIndex) {
      TweenMax.to(photoSelected, 0.5, {
        ease: "Power2.easeInOut",
        x: (this.state.imageIndex - i) * leftPos - window.innerWidth / 2,
        autoAlpha: 0
      });
    } else if (i > this.state.imageIndex) {
      TweenMax.to(photoSelected, 0.5, {
        ease: "Power2.easeInOut",
        x: (i - this.state.imageIndex) * rightPos + window.innerWidth / 2,
        autoAlpha: 0
      });
    }

    this.setState({
      imgLoaded
    });
  };

  placeImgForEach = e => {
    this.state.photosDiapo.forEach((item, i) => this.placeImg(i));
  };

  setImgWidth = () => {
    let width = this.props.windowDimensions.windowWidth;
    if (width > 1200) {
      return width;
    } else {
      return 1200;
    }
  };

  render() {
    const { match, location, history } = this.props;
    // console.log("modal", location.pathname, location.pathname.split("/"));
    return (
      <ModalWrapper
        id={"modal"}
        tabIndex="-1"
        // onClick={() => this.setState({ isModalToggled: false })}
        ref={this.modal}
      >
        <Close onClick={this.closeModal} />

        {this.state.photosDiapo.map((item, i) => {
          if (item.img) {
            return (
              // <LazyLoad>
              <ModalImage
                src={cl.url(item.img.public_id, {
                  width: this.setImgWidth(),
                  height: this.setImgWidth(),
                  crop: "fit"
                })}
                id={"image-modal-" + i}
                className="image-modal"
                onClick={() => console.log(item)}
                style={{
                  opacity: 0
                }}
                onLoad={() => this.placeImg(i)}
                key={`img-modal-${item.img.id}`}
              />
              // </LazyLoad>
            );
          } else if (item.vid) {
            return (
              <ModalVideo
                id={"image-modal-" + i}
                className="image-modal"
                // loop
                controls={this.state.imageIndex === i}
                style={{
                  opacity: 0
                }}
                // onCanPlay={() => this.placeImg(i)}
                key={`img-modal-${item.vid.id}`}
                onProgress={e => {
                  this.placeImg(i);
                }}
              >
                <source src={item.vid.url} type={item.vid.mime} />
                Sorry, your browser doesn't support embedded videos.
              </ModalVideo>
            );
          }
        })}
        {this.state.imageIndex - 1 >= 0 && (
          <ArrowContainer
            style={{
              left: 0
            }}
            onClick={() => this.onImageClick(this.state.imageIndex - 1)}
          >
            <Arrow
              style={{ transform: "rotate(180deg)" }}
              color={this.props.colorMode.textColor}
            />
          </ArrowContainer>
        )}
        {this.state.imageIndex + 1 <= this.state.photosDiapo.length - 1 && (
          <ArrowContainer
            style={{
              right: 0
            }}
            onClick={() => this.onImageClick(this.state.imageIndex + 1)}
          >
            <Arrow color={this.props.colorMode.textColor} />
          </ArrowContainer>
        )}
        <SerieTitle color={this.props.colorMode.textColor}>
          {this.state.serieTitle}{" "}
          <SerieArrow color={this.props.colorMode.textColor} />
        </SerieTitle>
        <Plus
          onClick={() =>
            this.easeOut(() =>
              history.push("/" + location.pathname.split("/")[1])
            )
          }
          colorMode={this.props.colorMode}
          style={{
            position: "absolute",
            top: 30,
            bottom: 0,
            right: 50,
            // margin: "auto 0",
            transform: "scale(2) rotate(45deg)",
            cursor: "pointer"
          }}
        />
      </ModalWrapper>
    );
  }
}

export default withRouter(ImageModal);
