import React, { Component } from "react";
import styled from "styled-components";
import ImageModal from "../Modal";
import InfosModal from "../Modal/InfosModal";
import TagsModal from "../Modal/TagsModal";
import { Route } from "react-router-dom";
import Column from "../Column";
import Image from "../Image";
import Video from "../Image/Video";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  z-index: 100;
  overflow-y: scroll;
  margin-top: 100px;
  scrollbar-color: ${({ colorMode }) => colorMode.textColor} ${({
  colorMode
}) => colorMode.bgColor};
  /* &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ colorMode }) => colorMode.bgColor}; 
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ colorMode }) => colorMode.textColor}; 
  } */
  @media (max-width: 960px) {
    justify-content: space-between;
  }
`;

const chunkArray = (arrPhotos, size) => {
  let arr = [...arrPhotos];
  let results = [];
  // let results = [];
  let modulo = arr.length % size;
  let division = (arr.length - modulo) / size;
  for (var i = 0; i < size; i++) {
    results.push([]);
  }
  arr.forEach((item, i) => {
    results[i % size].push(item);
  });
  return results;
};

class Home extends Component {
  state = {
    photos: [],
    nbrColumn: 5
  };
  componentDidMount() {
    this.setNbrColumn();
    this.setPhotosColumn();
  }
  setNbrColumn = () => {
    if (this.props.nbrColumn) {
      this.setState({ nbrColumn: this.props.nbrColumn });
    } else {
      switch (this.props.device) {
        case "mobile":
          this.setState({ nbrColumn: 2 });
          break;
        case "desktop":
          this.setState({ nbrColumn: 5 });
          break;
        default:
          this.setState({ nbrColumn: 5 });
      }
    }
  };

  filterImages = () => {
    const { photos, filter, tags } = this.props;
    // console.log(photos, filter, tags);
    let photosFiltered = [];
    if (filter === "all") {
      photosFiltered = [
        ...photos.filter(item => item.onHome === true)
        // ...videos.filter(item => item.onHome === true)
      ];
    } else {
      const tagsFiltered = tags.map(tag => {
        tag.photos = photos.filter(item => {
          return item.tags.findIndex(a => a.id === tag.id) !== -1;
        });
        return tag;
      });
      // console.log(filter);
      // console.log(tagsFiltered.find(item => item.title === filter));
      photosFiltered = [
        ...tagsFiltered.find(item => item.title === filter).photos,
        ...tagsFiltered.find(item => item.title === filter).videos
      ];
    }
    // console.log(photosFiltered);
    return photosFiltered;
  };

  setPhotosColumn = () => {
    let chunk = chunkArray(this.props.filteredPhotos, this.state.nbrColumn);
    this.setState({
      photos: chunk
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter !== this.props.filter) {
      this.setPhotosColumn();
    }
    if (this.state.nbrColumn !== prevState.nbrColumn) {
      this.setState({ photos: [] }, () => this.setPhotosColumn());
    }
    // if (prevState.photos !== this.state.photos) {
    //   this.setPhotosColumn();
    // }
    if (this.props.photos !== prevProps.photos) {
      this.setPhotosColumn();
    }
    if (this.props.device !== prevProps.device) {
      if (this.props.device === "desktop") {
        this.setNbrColumn();
      } else if (this.props.device === "mobile") {
        this.setNbrColumn();
      }
    }
  }
  render() {
    // console.log("state", this.state);
    // console.log("props imgContainer", this.props);
    const { match } = this.props;
    const prevMatchModal = { ...match };
    // console.log(this.props.windowDimensions);
    return [
      <Wrapper className="photos-wrapper" colorMode={this.props.colorMode}>
        {this.state.photos.length > 1 &&
          this.state.photos.map((col, colIndex) => (
            <Column
              style={{
                width:
                  this.props.device === "mobile"
                    ? this.props.windowDimensions.windowWidth /
                        this.state.nbrColumn -
                      5
                    : ((this.props.windowDimensions.windowWidth > 1500
                        ? 1500
                        : this.props.windowDimensions.windowWidth) -
                        500) /
                        this.state.nbrColumn -
                      25,
                margin: this.props.device === "mobile" ? "0" : "0 20px"
              }}
              className={this.props.device === "mobile" && `col-${colIndex}`}
            >
              {col &&
                col.map((img, i) => {
                  if (img.img) {
                    return (
                      <Image
                        nbrColumn={this.state.nbrColumn}
                        device={this.props.device}
                        colorMode={this.props.colorMode}
                        windowDimensions={{
                          windowWidth:
                            this.props.device === "mobile"
                              ? this.props.windowDimensions.windowWidth
                              : (this.props.windowDimensions.windowWidth > 1500
                                  ? 1500
                                  : this.props.windowDimensions.windowWidth) -
                                500,
                          windowHeight:
                            this.props.device === "mobile"
                              ? this.props.windowDimensions.windowHeight
                              : this.props.windowDimensions.windowHeight
                        }}
                        style={{
                          margin:
                            this.props.device === "mobile" ? "5px 0" : "20px 0"
                        }}
                        filter={this.props.filter}
                        id={`${this.props.filter}col-col${colIndex}-img${i}`}
                        className={`col-${colIndex} img${i} img`}
                        key={this.props.filter + "col-" + colIndex + "-img" + i}
                        title={img.img.name}
                        src={img.img.url}
                        imgId={img.img.id}
                        photo={img}
                      />
                    );
                  } else if (img.vid) {
                    return (
                      <Video
                        nbrColumn={this.state.nbrColumn}
                        device={this.props.device}
                        colorMode={this.props.colorMode}
                        windowDimensions={{
                          windowWidth:
                            this.props.device === "mobile"
                              ? this.props.windowDimensions.windowWidth
                              : (this.props.windowDimensions.windowWidth > 1500
                                  ? 1500
                                  : this.props.windowDimensions.windowWidth) -
                                500,
                          windowHeight:
                            this.props.device === "mobile"
                              ? this.props.windowDimensions.windowHeight
                              : this.props.windowDimensions.windowHeight
                        }}
                        style={{
                          margin:
                            this.props.device === "mobile" ? "5px 0" : "20px 0"
                        }}
                        filter={this.props.filter}
                        id={`${this.props.filter}col-col${colIndex}-img${i}`}
                        className={`col-${colIndex} img${i} img`}
                        key={this.props.filter + "col-" + colIndex + "-img" + i}
                        src={img.vid.url}
                        title={img.vid.name}
                        imgId={img.vid.id}
                        photo={img}
                      />
                    );
                  }
                })}
            </Column>
          ))}
      </Wrapper>,
      <Route
        path={`${match.url}/img/:imgId`}
        render={props => (
          <ImageModal
            photos={this.props.photos}
            series={this.props.series}
            windowDimensions={this.props.windowDimensions}
            device={this.props.device}
            colorMode={this.props.colorMode}
            prevMatchModal={prevMatchModal}
            {...props}
          />
        )}
      />,
      <Route
        path={`${match.url}/informations`}
        render={props => (
          <InfosModal
            colorMode={this.props.colorMode}
            windowDimensions={this.props.windowDimensions}
            device={this.props.device}
            prevMatchModal={prevMatchModal}
            informations={this.props.informations}
          />
        )}
      />,
      <Route
        path={`${match.url}/tags`}
        render={props => (
          <TagsModal
            colorMode={this.props.colorMode}
            windowDimensions={this.props.windowDimensions}
            device={this.props.device}
            prevMatchModal={prevMatchModal}
            tags={this.props.tags}
          />
        )}
      />
    ];
  }
}

export default Home;
