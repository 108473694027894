/* hocs/defaultPage.js */

import React from "react";
// import Router from "next/router";
import { Redirect } from "react-router-dom";

import { getUserFromServerCookie, getUserFromLocalCookie } from "../lib/auth";

export default Page =>
  class DefaultPage extends React.Component {
    addProps = {};
    componentWillMount() {
      const loggedUser = process.browser
        ? getUserFromLocalCookie()
        : getUserFromServerCookie();
      // const pageProps = Page.getInitialProps && Page.getInitialProps(req);
      // console.log("is authenticated");
      // console.log(loggedUser);
      // let path = req ? req.pathname : "";
      // path = "";
      this.addProps = {
        // ...pageProps,
        loggedUser,
        // currentUrl: path,
        isAuthenticated: !!loggedUser
      };
    }

    logout = eve => {
      if (eve.key === "logout") {
        // return this.props.history.push("/");
      }
    };

    componentDidMount() {
      // console.log("mounted");
      window.addEventListener("storage", this.logout, false);
    }

    componentWillUnmount() {
      window.removeEventListener("storage", this.logout, false);
    }

    render() {
      return <Page {...this.props} {...this.addProps} />;
    }
  };
