import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 999;
  &:hover input {
    background: rgba(217, 217, 217, 0.42);
  }
  & input:focus {
    background: rgba(217, 217, 217, 0.42);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 90px;
  position: absolute;
  right: 0;
  top: 0;
  background: ${({ colorMode }) => colorMode.bgColor};
`;

const Button = styled.div`
  width: calc(100% - 2px);
  height: 28px;
  margin: 5px 0;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  font-family: "SFProText-Bold";
  font-size: 15px;
  cursor: pointer;
  align-items: center;
`;

const More = styled.div`
  width: 20%;
  height: 28px;
  margin: 10px 0;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ colorMode }) => colorMode.textColor};
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
`;

const Title = styled.input`
  font-family: "SFProText-Bold";
  font-size: 13px;
  width: calc(80% - 20px);
  border: none;
  text-align: justify;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  letter-spacing: 1px;
  background: none;
  margin: 10px 0;
  border-radius: 2px;
  padding: 5px 10px;
  transition: background 0.5s ease;
  color: ${({ colorMode }) => colorMode.textColor};
`;

class Input extends Component {
  state = {
    title: "",
    morIsOpen: false
  };

  componentDidMount() {
    this.setState({
      title: this.props.title
    });
  }

  render() {
    return (
      <Wrapper>
        <Title
          value={this.state.title}
          colorMode={this.props.colorMode}
          onChange={e => this.setState({ title: e.target.value })}
          onBlur={() => this.props.updateTitle(this.state.title)}
        />
        <More
          className="photo-more"
          colorMode={this.props.colorMode}
          onClick={e => this.setState({ moreIsOpen: true })}
        >
          •••
        </More>
        {this.state.moreIsOpen ? (
          <ButtonWrapper colorMode={this.props.colorMode}>
            <Button
              style={{
                backgroundColor: "#FF3535",
                border: "1px solid #FF3535",
                color: "#FFF"
              }}
              onClick={() => {
                this.props.deletePhoto();
                this.setState({ isOpen: false });
              }}
            >
              Delete Photo
            </Button>
            <Button
              onClick={e => this.setState({ moreIsOpen: false })}
              style={{
                border: "1px solid " + this.props.colorMode.textColor,
                color: this.props.colorMode.textColor
              }}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export default Input;
