import React, { Component } from "react";
import styled from "styled-components";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
import Nearby from "../../utils/nearby";
import { withRouter, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import InfosModal from "../Modal/InfosModal";

const Container = styled.div`
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  color: ${({ colorMode }) => colorMode.textColor};
  background: ${({ colorMode }) => colorMode.bgColor};
  overflow: hidden;
  z-index: 999;
`;

const Wrapper = styled.div`
  width: ${({ device }) => (device === "desktop" ? "50%" : "90%")};
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const Item = styled.div`
  height: 100px;
  font-size: 20px;
  font-family: "Interstate";
  /* font-weight: 800; */
  cursor: pointer;
  position: relative;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
  &:hover .btn-Menu {
    height: ${({ isFilter }) => (isFilter ? "20px" : "10px")};
    opacity: 1;
  }
  &:hover .btn-infos {
    width: 10px;
    opacity: 1;
  }
  & > h1 {
    font-size: inherit;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
  @media (max-width: 961px) {
    width: 100%;
    & > h1 {
      position: absolute;
      left: 0;
      top: 30%;
    }
    & > #cross {
      position: absolute;
      right: 0;
      left: auto;
      top: 66%;
      bottom: auto;
      margin: 0;
    }
  }
`;

const Span = styled.span`
  position: absolute;
  top: calc(50% + 18px);
  ${"" /* display: ${({ isFilter }) => (isFilter ? "block" : "none")} */}
  width: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: ${({ isFilter }) => (isFilter ? "20px" : "0")};
  float: left;
  opacity: ${({ isFilter }) => (isFilter ? "1" : "0")};
  background-color: ${({ colorMode }) => colorMode.textColor};
  transition: opacity 0.2s ease, height 0.2s ease-in-out;
`;

const LeftSpan = styled.span`
  position: absolute;
  top: 0;
  ${"" /* display: ${({ isFilter }) => (isFilter ? "block" : "none")} */}
  height: 2px;
  left: calc(100% + 10px);
  right: 0;
  bottom: 0;
  margin: auto 0;
  width: ${({ isFilter }) => (isFilter ? "30px" : "0")};
  float: left;
  opacity: ${({ isFilter }) => (isFilter ? "1" : "0")};
  background-color: ${({ colorMode }) => colorMode.textColor};
  transition: opacity 0.2s ease-in, width 0.2s ease-in-out;
`;

const Cross = styled.span`
  position: absolute;
  font-size: 22px;
  top: calc(50% + 15px);
  transform-origin: center;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const lineEq = (y2, y1, x2, x1, currentVal) => {
  // y = mx + b
  var m = (y2 - y1) / (x2 - x1),
    b = y1 - m * x1;
  return m * currentVal + b;
};

let MenuNearby;
let scrollPos = 0;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.menuWrapper = null;
    this.title = null;
    this.container = null;
    this.state = {
      isTitleDisplayed: true,
      scrollPos: 0,
      mouseIn: false
    };
  }
  componentDidMount() {
    // TweenMax.to(document.querySelector(".btn-" + this.props.filter), 0.5, {
    //   ease: "Expo.easeInOut",
    //   height: 40,
    //   autoAlpha: 1
    // });
    if (
      this.props.location &&
      this.props.location.pathname.search("/admin") === -1
    ) {
      window.onscroll = e => {
        // if (document.body.getBoundingClientRect().top < this.state.scrollPos) {
        if (this.props.device === "mobile") {
          TweenMax.to(this.menuWrapper, 0.5, {
            ease: "Expo.easein",
            top: 100,
            autoAlpha: 0
          });
          TweenMax.to(this.title, 0.5, {
            ease: "Expo.easein",
            bottom: 25,
            autoAlpha: 1
          });
          TweenMax.to(this.cross, 0.5, {
            ease: "Expo.easein",
            rotation: 0
          });
          // }
        }
        this.setState({ scrollPos: document.body.getBoundingClientRect().top });
      };
      const topInterval = { from: 0, to: 100 };
      const opacityInterval = { from: 0, to: 1 };
      const crossInterval = { from: 0, to: 90 };
      MenuNearby = new Nearby(this.container, {
        onProgress: distance => {
          const scaleCross = lineEq(
            crossInterval.from,
            crossInterval.to,
            this.props.device === "desktop" ? 50 : 1,
            0,
            distance
          );
          const scaleTopMenuWrapper = lineEq(
            topInterval.to,
            topInterval.from,
            this.props.device === "desktop" ? 50 : 1,
            0,
            distance
          );

          const scaleOpacityMenuWrapper = lineEq(
            opacityInterval.from,
            opacityInterval.to,
            this.props.device === "desktop" ? 50 : 1,
            0,
            distance
          );
          const scaleOpacityTitle = lineEq(
            opacityInterval.to,
            opacityInterval.from,
            this.props.device === "desktop" ? 50 : 1,
            0,
            distance
          );
          const scaleTopTitle = lineEq(
            topInterval.from + 25,
            topInterval.to + 25,
            this.props.device === "desktop" ? 50 : 1,
            0,
            distance
          );
          distance !== 0
            ? this.setState({ isTitleDisplayed: true })
            : this.setState({ isTitleDisplayed: false });
          TweenMax.to(this.menuWrapper, 0.5, {
            ease: "Expo.easein",
            top: Math.max(
              Math.min(scaleTopMenuWrapper, topInterval.to),
              topInterval.from
            ),
            autoAlpha: Math.max(
              Math.min(scaleOpacityMenuWrapper, opacityInterval.to),
              opacityInterval.from
            )
          });
          TweenMax.to(this.title, 0.5, {
            ease: "Expo.easein",
            bottom: Math.max(
              Math.min(scaleTopTitle, topInterval.to + 50),
              topInterval.from + 25
            ),
            autoAlpha: Math.max(
              Math.min(scaleOpacityTitle, opacityInterval.to),
              opacityInterval.from
            )
          });
          TweenMax.to(this.cross, 0.5, {
            ease: "Expo.easein",
            rotation: Math.max(
              Math.min(scaleCross, crossInterval.to),
              crossInterval.from
            )
          });
        }
      });
      MenuNearby.add();
    }
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.filter !== prevProps.filter) {
  //     TweenMax.to(document.querySelector(".btn-" + prevProps.filter), 0.5, {
  //       ease: "Expo.easeInOut",
  //       height: 0,
  //       autoAlpha: 0
  //     });
  //     TweenMax.to(document.querySelector(".btn-" + this.props.filter), 0.5, {
  //       ease: "linear",
  //       height: 40,
  //       autoAlpha: 1
  //     });
  //   }
  // }
  render() {
    const { match, location, history } = this.props;
    if (location.pathname.search("/admin") !== -1) {
      return null;
    } else {
      return (
        <Container
          ref={div => (this.container = div)}
          colorMode={this.props.colorMode}
          onMouseEnter={e => this.setState({ mouseIn: true })}
          onMouseLeave={e => this.setState({ mouseIn: false })}
        >
          <Wrapper
            device={this.props.device}
            ref={div => (this.title = div)}
            style={{ bottom: 25 }}
          >
            <Item>
              <h1>SIMON ESCOURBIAC</h1>
              <Cross id="cross" ref={div => (this.cross = div)}>
                +
              </Cross>
            </Item>
          </Wrapper>
          <Wrapper
            device={this.props.device}
            ref={div => (this.menuWrapper = div)}
            style={{ top: 200 }}
            className="menu"
          >
            <Item
              onClick={() => this.props.handleFilterClick("all", history)}
              isFilter={location.pathname === "/all"}
              style={{ top: 0 }}
            >
              HOME
              <Span
                className="btn-All btn-Menu"
                colorMode={this.props.colorMode}
                isFilter={location.pathname === "/all"}
              />
            </Item>
            <Item
              onClick={() => this.props.handleFilterClick("textures", history)}
              isFilter={location.pathname === "/textures"}
            >
              TEXTURES
              <Span
                className="btn-Textures btn-Menu"
                colorMode={this.props.colorMode}
                isFilter={location.pathname === "/textures"}
              />
            </Item>
            <Item
              onClick={() => this.props.handleFilterClick("still", history)}
              isFilter={location.pathname === "/still"}
            >
              STILL
              <Span
                className="btn-Still btn-Menu"
                colorMode={this.props.colorMode}
                isFilter={location.pathname === "/still"}
              />
            </Item>
            <Item
              onClick={() => this.props.handleFilterClick("ads", history)}
              isFilter={location.pathname === "/ads"}
            >
              ADVERTISING
              <Span
                className="btn-Advertising btn-Menu"
                colorMode={this.props.colorMode}
                isFilter={location.pathname === "/ads"}
              />
            </Item>
            {/* <Item
              onClick={() => history.push(`${location.pathname}/tags`)}
              isFilter={false}
            >
              •••
              <Span
                className="btn-Advertising btn-Menu"
                colorMode={this.props.colorMode}
                // isFilter={location.pathname === "/ads"}
              />
            </Item> */}
            <Item
              onClick={() => history.push(`${location.pathname}/informations`)}
            >
              +ABOUT
              <LeftSpan
                className="btn-infos"
                colorMode={this.props.colorMode}
                isFilter={this.props.filter === "info"}
              />
            </Item>
          </Wrapper>
        </Container>
      );
    }
  }
}

export default withRouter(Menu);
