import React, { Component } from "react";
import styled from "styled-components";
import { TweenMax, TimelineLite } from "gsap/TweenMax";
import "gsap/src/uncompressed/plugins/ScrollToPlugin";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  disablePageScroll,
  enablePageScroll,
  addFillGapSelector
} from "scroll-lock";
import Markdown from "react-markdown";

const Plus = props => (
  <svg width={15} height={14} {...props}>
    <g
      stroke={props.colorMode.textColor}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M7.5 13V1.5M1.5 7h12" />
    </g>
  </svg>
);

const lineEq = (y2, y1, x2, x1, currentVal) => {
  var m = (y2 - y1) / (x2 - x1),
    b = y1 - m * x1;
  return m * currentVal + b;
};

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.90)"
    );
  }
  return hex;
}

const CloseWrapper = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

const TagsWrapper = styled.div`
  width: calc(100% - 140px);
  padding: 0 70px;
  display: flex;
`;

const Title = styled.div`
  font-family: "Interstate";
  font-size: 25px;
  text-transform: uppercase;
  margin: 50px 0;
`;

const Text = styled.div`
  font-family: "Interstate";
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 14px;
  margin-left: 25px;
  margin-right: 25px;
  text-align: left;
  line-height: 17px;
`;

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100000;
  background: #bb6767;
  left: 0;
  opacity: 0;
  top: 100vh;
  & > img {
    position: absolute;
    top: 0vh;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  & * {
    color: ${({ colorMode }) => colorMode.textColor};
  }
  @media (max-width: 960px) {
    overflow-y: scroll;
  }
`;
class ImageModal extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.easeIn();
    let photoWrapper = document.getElementsByClassName(".photos-wrapper");
    // disablePageScroll(photoWrapper);
    // addFillGapSelector();
  }

  easeOut = cb => {
    let wrapperOutTimeline = new TimelineLite();
    wrapperOutTimeline
      .to(document.getElementById("modal"), 0.2, {
        ease: "Expo.easeout",
        autoAlpha: 0
      })
      .to(document.getElementById("modal"), 0.2, {
        ease: "Expo.easein",
        y: this.props.windowDimensions.windowHeight * 2 + "px",
        zIndex: 100,
        onComplete: cb
      });
  };

  easeIn = () => {
    let wrapperInTimeline = new TimelineLite();

    wrapperInTimeline
      .set(document.getElementById("modal"), {
        top: 0,
        y: 0,
        zIndex: 10000
        // backgroundColor: bgColor
      })
      .to(document.getElementById("modal"), 0.4, {
        ease: "Expo.easeout",
        autoAlpha: 1
      });
  };

  componentWillUnmount() {}

  render() {
    const { match, location, history } = this.props;
    // console.log(this.props.tags);
    return (
      <ModalWrapper
        id={"modal"}
        colorMode={this.props.colorMode}
        style={{
          background:
            hexToRgbA(this.props.colorMode.bgColor) ||
            this.props.colorMode.bgColor
        }}
        tabIndex="-1"
        ref={this.modal}
      >
        <Plus
          colorMode={this.props.colorMode}
          onClick={() => this.easeOut(() => history.goBack())}
          style={{
            position: "absolute",
            top: 50,
            bottom: 0,
            right: 50,
            // margin: "auto 0",
            transform: "scale(2) rotate(45deg)",
            cursor: "pointer"
          }}
        />
        <Title>All Tags</Title>
        <TagsWrapper>
          {this.props.tags.map(tag => (
            <Link to={`/${tag.title}`}>
              <Text>#{tag.title}</Text>
            </Link>
          ))}
        </TagsWrapper>
        <div style={{ position: "relative", width: "100%", minHeight: "100%" }}>
          {/* <CloseWrapper> */}

          {/* </CloseWrapper> */}
        </div>
      </ModalWrapper>
    );
  }
}

export default withRouter(ImageModal);
