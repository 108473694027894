import React, { Component } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import SecurePage from "../SecurePage";
import DefaultPage from "../DefaultPage";

import { strapiLogin, unsetToken } from "../lib/auth";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Input = styled.input`
  width: 250px;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  padding: 5px 0 5px 0;
  font-size: 16px;
  background: none;
  font-family: "Helvetica Neue";
  font-weight: 300;
  letter-spacing: 1px;
`;

const Button = styled.div`
  padding: 10px 15px;
  border: 1px solid #000;
  background-color: transparent;
  transition: background-color 0.5s ease, border 0.5s ease, color 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: ${({ colorMode }) => colorMode.textColor} !important;
    color: ${({ colorMode }) => colorMode.bgColor} !important;
  }
`;

const Form = styled.div`
  width: 250px;
  height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  & input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({ colorMode }) => colorMode.bgColor} inset !important;
    -webkit-text-fill-color: ${({ colorMode }) =>
      colorMode.textColor} !important;
  }
`;

class Login extends Component {
  state = {
    userId: "",
    password: ""
  };

  onSubmit = () => {
    const { userId, password } = this.state;
    // const { context } = this.props;

    this.setState({ loading: true });

    strapiLogin(userId, password, this.props.history).then(() =>
      console.log(Cookies.get("username"))
    );
  };

  // static async getInitialProps(ctx) {
  //   let pageProps = {};
  //   if (Component.getInitialProps) {
  //     pageProps = await Component.getInitialProps(ctx);
  //   }
  //
  //   return { pageProps };
  // }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/admin/series"); // redirect if you're already logged in
    }
  }

  keyPressed = event => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  //
  // componentDidUpdate(prevProps) {
  //   if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
  //     if (this.props.isAuthenticated) {
  //       console.log("route push");
  //       this.props.history.push("/admin/series");
  //     }
  //   }
  // }

  render() {
    const { isAuthenticated, children } = this.props;
    // console.log("login props: ", this.props);
    return (
      <Form colorMode={this.props.colorMode}>
        <Input
          type="text"
          name="userId"
          onChange={e => this.setState({ userId: e.target.value })}
          onKeyPress={this.keyPressed}
          colorMode={this.props.colorMode}
          style={{
            color: this.props.colorMode.textColor
          }}
        />
        <Input
          type="password"
          name="password"
          onChange={e => this.setState({ password: e.target.value })}
          colorMode={this.props.colorMode}
          onKeyPress={this.keyPressed}
          style={{
            color: this.props.colorMode.textColor
          }}
        />
        <Button
          colorMode={this.props.colorMode}
          style={{
            color: this.props.colorMode.textColor,
            border: `1px solid ${this.props.colorMode.textColor}`
          }}
          onClick={this.onSubmit}
        >
          ENTER
        </Button>
      </Form>
    );
  }
}

export default DefaultPage(Login);
