import React, { Component } from "react";
import styled from "styled-components";

const ArrowDown = props => (
  <svg width={15} height={7} {...props}>
    <path
      d="M1 1l6.5 4L14 1"
      stroke={props.colorMode.textColor}
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

const Trash = props => (
  <svg width={15} height={15} {...props}>
    <path
      fill="none"
      strokeWidth={1.5}
      stroke={props.colorMode.textColor}
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M.5 2.5h12M2.5 12.5h8M4.5.5h4M4.5 6v3M8.5 6v3M6.5 6v3M1.5 2.5l1 10M11.5 2.5l-1 10"
    />
  </svg>
);

const PlusTag = props => (
  <svg width={15} height={14} {...props}>
    <g
      stroke={props.colorMode.textColor}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M7.5 13V1.5M1.5 7h12" />
    </g>
  </svg>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  &:hover input {
    background: rgba(217, 217, 217, 0.42);
  }
  & input:focus {
    background: rgba(217, 217, 217, 0.42);
  }
`;

const ButtonWrapper = styled.div`
  width: calc(100% - 2px);
  position: absolute;
  right: 0;
  z-index: 999;
  border-radius: 2px;
  top: -40px;
  background: ${({ colorMode }) => colorMode.bgColor};
  border: 1px solid ${({ colorMode }) => colorMode.textColor};
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.42);
`;

const TagSelect = styled.div`
  width: calc(100% - 10px);
  height: 28px;
  padding: 5px;
  display: flex;
  text-transform: uppercase;
  font-family: "VCROSDMono";
  position: relative;
  font-size: 13px;
  color: ${({ colorMode }) => colorMode.textColor};
  cursor: pointer;
  align-items: center;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
  &:hover {
    background: #8c8c8c;
  }
`;

const More = styled.div`
  width: 20%;
  height: 28px;
  margin: 10px 0;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Title = styled.div`
  font-family: "VCROSDMono";
  font-size: 13px;
  width: calc(100% - 20px);
  height: 28px;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  background: none;
  border-radius: 2px;
  padding: 5px 10px;
  transition: background 0.5s ease;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ colorMode }) => colorMode.textColor};
  }
`;

const NewTag = styled.input`
  width: calc(100% - 10px);
  height: 28px;
  padding: 5px;
  display: flex;
  text-transform: uppercase;
  font-family: "VCROSDMono";
  position: relative;
  font-size: 13px;
  border: none;
  color: ${({ colorMode }) => colorMode.textColor};
  cursor: pointer;
  align-items: center;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
  &:hover {
    background: #8c8c8c;
  }
`;

const Tag = styled.div`
  padding: 5px;
  font-family: "VCROSDMono";
  font-size: 13px;
  background-color: #eaeaea;
  color: #000;
  text-transform: uppercase;
  display: flex;
  border-radius: 2px;
  align-items: center;
  margin-right: 5px;
`;

class Select extends Component {
  state = {
    title: "",
    isOpen: false,
    tagsIdarr: []
  };

  componentDidMount() {
    this.setState({
      tagsIdarr: this.props.img.tags
    });
    window.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClick, false);
  }

  tagClick = tagId => {
    let tags = [...this.state.tagsIdarr];
    let tagFound = tags.indexOf(tagId);
    // console.log("found: ", tags);
    if (tagFound === -1) {
      // console.log(tagId);
      tags.push({ _id: tagId });
      this.setState(
        {
          tagsIdarr: tags
        },
        () => this.props.updateTags(this.state.tagsIdarr)
      );
    } else if (tagFound !== -1) {
      tags.splice(tagFound, 1);
      this.setState(
        {
          tagsIdarr: tags
        },
        () => this.props.updateTags(this.state.tagsIdarr)
      );
    }
  };

  handleClick = (e, tagId) => {
    if (this.wrapper.contains(e.target)) {
      return;
    }
    this.setState({
      isOpen: false
    });
  };

  handleNewTag = () => {
    this.props.addTag(this.state.title);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.img.tags !== this.props.img.tags) {
      this.setState({
        tagsIdarr: this.props.img.tags
      });
    }
  }

  render() {
    // console.log("tags states", this.state);
    // console.log("tags props", this.props);
    return (
      <Wrapper ref={div => (this.wrapper = div)}>
        <Title
          colorMode={this.props.colorMode}
          onClick={() =>
            this.state.isOpen ? "" : this.setState({ isOpen: true })
          }
        >
          {this.state.tagsIdarr.length !== 0 ? (
            this.props.tags
              .filter(item => this.state.tagsIdarr.indexOf(item._id) !== -1)
              .map((item, i) => <Tag>{item.title}</Tag>)
          ) : (
            <Tag>NO TAGS YET</Tag>
          )}
          <ArrowDown
            style={{
              position: "absolute",
              right: "15px",
              top: 0,
              bottom: 0,
              margin: "auto 0",
              cursor: "pointer"
            }}
            colorMode={this.props.colorMode}
          />
        </Title>
        {this.state.isOpen ? (
          <ButtonWrapper colorMode={this.props.colorMode}>
            {this.props.tags.map(item => (
              <TagSelect
                colorMode={this.props.colorMode}
                isActive={this.state.tagsIdarr.indexOf(item._id) !== -1}
              >
                {item.title}
                <Trash
                  colorMode={this.props.colorMode}
                  onClick={() => this.props.deleteTag(item.id)}
                  style={{
                    position: "absolute",
                    right: "45px",
                    top: 0,
                    bottom: 0,
                    margin: "auto 0",
                    cursor: "pointer"
                  }}
                ></Trash>
                <PlusTag
                  colorMode={this.props.colorMode}
                  onClick={() => this.tagClick(item._id)}
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: 0,
                    bottom: 0,
                    margin: "auto 0",
                    cursor: "pointer",
                    transform:
                      this.state.tagsIdarr.indexOf(item._id) === -1
                        ? "rotate(0)"
                        : "rotate(45deg)"
                  }}
                />
              </TagSelect>
            ))}
            <div style={{ position: "relative" }}>
              <NewTag
                colorMode={this.props.colorMode}
                value={this.state.title}
                placeholder="New Tag..."
                onChange={e => this.setState({ title: e.target.value })}
                // onClick={() => this.tagClick(item._id)}
                isActive={false}
              ></NewTag>
              <PlusTag
                colorMode={this.props.colorMode}
                onClick={this.handleNewTag}
                style={{
                  position: "absolute",
                  right: "15px",
                  top: 0,
                  bottom: 0,
                  margin: "auto 0",
                  cursor: "pointer",
                  transform: "rotate(0deg)"
                }}
              />
            </div>
          </ButtonWrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export default Select;
