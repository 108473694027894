import React, { Component } from "react";
import styled from "styled-components";

const Column = styled.div`
  height: 100%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 15px;
  }
  @media (max-width: 961px) {
    margin: 0;
  }
`;

// class Column extends Component {
//   render() {
//     return <Wrapper>{this.props.children}</Wrapper>;
//   }
// }

export default Column;
