import React, { Component } from "react";
import { Editor, EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

class ContentEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        stateFromMarkdown(this.props.content)
      )
    };
    this.onChange = editorState => {
      this.props.onChange(editorState, this.props.id, this.props.index);
      this.setState({ editorState });
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content && this.props.reset === true) {
      // console.log(true);
      this.onChange(
        EditorState.createWithContent(stateFromMarkdown(this.props.content))
      );
    }
  }
  render() {
    return (
      <div
        style={{
          minHeight: 500,
          width: 300,
          color: this.props.colorMode.textColor,
          border: `2px solid #E4E4E4`,
          margin: 15,
          textAlign: "left"
        }}
      >
        <div
          style={{
            padding: "15px 20px",
            fontFamily: "helvetica neue",
            fontWeight: 800,
            fontSize: 23,
            textAlign: "left",
            borderBottom: `2px solid #E4E4E4`,
            textTransform: "uppercase"
          }}
        >
          {this.props.title}
        </div>
        <div
          style={{
            padding: "15px 20px",
            fontFamily: "VCROSDMono",
            fontSize: 16,
            textTransform: "uppercase",
            lineHeight: 1.4
          }}
        >
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            // {...this.props}
          />
        </div>

        {/* <input
          type="text"
          style={{ width: "100%", height: "100%" }}
          value={this.props.content}
        /> */}
      </div>
    );
  }
}

export default ContentEditor;
