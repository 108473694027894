import React, { PureComponent } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  &:hover input {
    background: rgba(217, 217, 217, 0.42);
  }
  & input:focus {
    background: rgba(217, 217, 217, 0.42);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: ${({ colorMode }) => colorMode.bgColor};
`;

const Button = styled.div`
  width: 100%;
  height: 28px;
  margin: 5px 0;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  font-family: "SFProText-Bold";
  font-size: 15px;
  cursor: pointer;
  align-items: center;
`;

const More = styled.div`
  width: 20%;
  height: 28px;
  margin: 10px 0;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({ colorMode }) => colorMode.textColor};
`;

const Title = styled.input`
  font-family: "SFProText-Bold";
  font-size: 15px;
  width: calc(80% - 20px);
  border: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: none;
  margin: 10px 0;
  border-radius: 2px;
  padding: 5px 10px;
  transition: background 0.5s ease;
  color: ${({ colorMode }) => colorMode.textColor};
`;

class Input extends PureComponent {
  state = {
    title: "",
    morIsOpen: false
  };

  componentDidMount() {
    this.setState({
      title: this.props.title
    });
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props !== prevProps) {
  //     let keys = Object.keys(prevProps);
  //     keys.forEach(item => {
  //       if (this.props[item] !== prevProps[item]) {
  //         console.log(item + " updated");
  //         console.log(this.props[item], "-----", prevProps[item]);
  //       }
  //     });
  //   }
  // }

  render() {
    return (
      <Wrapper>
        <Title
          value={this.state.title}
          colorMode={this.props.colorMode}
          onChange={e => this.setState({ title: e.target.value })}
          onBlur={() =>
            this.props.updateSerieTitle(this.state.title.toUpperCase())
          }
        />
        <More
          colorMode={this.props.colorMode}
          onClick={e => this.setState({ moreIsOpen: true })}
        >
          •••
        </More>
        {this.state.moreIsOpen ? (
          <ButtonWrapper colorMode={this.props.colorMode}>
            <Button
              style={{
                backgroundColor: "#FF3535",
                border: "1px solid #FF3535",
                color: "#FFF"
              }}
              onClick={this.props.deleteSerie}
            >
              Delete Serie
            </Button>
            <Button
              onClick={e => this.setState({ moreIsOpen: false })}
              style={{
                border: "1px solid " + this.props.colorMode.textColor,
                color: this.props.colorMode.textColor
              }}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export default Input;
