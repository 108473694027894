/* components/hocs/securePage.js */

import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import defaultPage from "../DefaultPage";

const securePageHoc = Page =>
  class SecurePage extends React.Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired
    };

    // static getInitialProps(ctx) {
    //   return Page.getInitialProps && Page.getInitialProps(ctx);
    // }

    render() {
      const { isAuthenticated } = this.props;
      // console.log("isAuthenticated", isAuthenticated);
      return isAuthenticated ? (
        <Page {...this.props} />
      ) : (
        <Redirect to="/admin" />
      );
    }
  };

export default Page => defaultPage(securePageHoc(Page));
