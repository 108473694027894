import React, { Component } from "react";
import styled from "styled-components";

import Input from "./Input.js";
import Select from "./Select.js";
import cloudinary from "cloudinary-core";

const cl = new cloudinary.Cloudinary({ cloud_name: "escourbiac" });

const Wrapper = styled.div`
  width: 275px;
  margin: 23px;
  display: flex;
  flex-direction: column;
  &:hover .photo-more {
    opacity: 1;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 208px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(231, 231, 231);
  position: relative;
  cursor: pointer;
  /* opacity: ${({ onHome }) => (onHome ? 0.5 : 1)}; */
  /* cursor: pointer; */
  ${({ onHome }) =>
    onHome &&
    `
  &::after{
    content: 'HOME';
    padding: 5px;
    position: absolute;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
    background-color: #EDEDED;
    border-radius: 2px;
    color: #000;
    top: 5px;
    left: 5px;
    font-size: 12px;
    font-family: "VCROSDMono";
    font-weight: 200;
  }
  `}
`;

const ImgInput = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.5s ease;
  opacity: ${({ drag }) => (drag ? 1 : 0)};
  }
`;

class Photo extends Component {
  constructor(props) {
    super(props);
    this.dropRef = React.createRef();
    this.state = {
      drag: false,
      title: ""
    };
  }

  onImgClick = () => {
    // console.log(this.props.data.onHome);
    if (this.props.data.onHome === true) {
      this.props.updateOnHome(false);
    } else {
      this.props.updateOnHome(true);
    }
  };

  componentDidMount() {
    this.setState({
      title: this.props.data.title || this.props.data.img.name
    });
  }

  render() {
    const { data } = this.props;
    // console.log(data);
    // console.log(data.img.name);
    return (
      <Wrapper>
        <Image
          ref={this.dropRef}
          onClick={() => this.onImgClick()}
          onHome={data.onHome}
          style={{
            backgroundImage:
              data.img &&
              `url(${cl.url(data.img.public_id, {
                width: Math.round(208 * 2),
                crop: "fit"
              })})`
          }}
        />
        <Input
          title={data.title || data.img.name}
          colorMode={this.props.colorMode}
          deletePhoto={() => this.props.deletePhoto(data.id)}
          updateTitle={newTitle => this.props.updateTitle(newTitle)}
        />
        <Select
          img={data}
          tags={this.props.tags}
          colorMode={this.props.colorMode}
          updateTags={this.props.updateTags}
          addTag={this.props.addTag}
          deleteTag={this.props.deleteTag}
        />
      </Wrapper>
    );
  }
}

export default Photo;
