import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import cloudinary from "cloudinary-core";

const cl = new cloudinary.Cloudinary({ cloud_name: "escourbiac" });

const Wrapper = styled.div`
  width: 500px;
  margin: 23px;
  display: flex;
  flex-direction: column;
  &:hover .photo-more {
    opacity: 1;
  }
`;

const Title = styled.div`
  font-family: "SFProText-Bold";
  font-size: 13px;
  width: calc(80% - 20px);
  border: none;
  text-align: justify;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  letter-spacing: 1px;
  background: none;
  margin: 10px 0;
  border-radius: 2px;
  padding: 5px 10px;
  transition: background 0.5s ease;
  color: ${({ colorMode }) => colorMode.textColor};
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999999999;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ uploading }) => (uploading ? 1 : 0)};
  pointer-events: ${({ uploading }) => (uploading ? "auto" : "none")};
  transition: opacity 0.2s ease-in;
`;

const loaderAnim = keyframes`
  0%{
    transform: scale(0.3)
  }
  50%{
    transform: scale(0.6)
  }
  100%{
    transform: scale(0.3)
  }
`;

const Loader = styled.div`
  width: 150px;
  height: 150px;
  transform: scale(0.3);
  border-radius: 100%;
  background: #000;
  animation: ${loaderAnim} 2s ease-in-out infinite;
`;

const Image = styled.div`
  width: 100%;
  height: 416px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgb(231, 231, 231); */
  position: relative;
  cursor: pointer;
  ${({ onHome }) =>
    onHome &&
    `
  &::after{
    content: 'HOME';
    padding: 5px;
    position: absolute;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
    background-color: #EDEDED;
    border-radius: 2px;
    color: #000;
    top: 5px;
    left: 5px;
    font-size: 12px;
    font-family: "VCROSDMono";
    font-weight: 200;
  }
  `}
`;

const ImgInput = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.5s ease;
  opacity: ${({ drag }) => (drag ? 1 : 0)};
  }
`;

class Video extends Component {
  constructor(props) {
    super(props);
    this.dropRef = React.createRef();
    this.state = {
      drag: false,
      uploading: false
    };
  }

  //   async updateOnHome(photoId, bool, type) {
  //     // console.log(photoId, bool, type);
  //     try {
  //       await this.props.strapi
  //         .updateEntry(type, String(photoId), {
  //           onHome: Boolean(bool)
  //         })
  //         .then(res => {
  //           // console.log("res", res);
  //           this.loadDb(this.props.match.params.serieId);
  //         });
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }

  async updateVideo(video, files) {
    try {
      let count = 0;
      console.log(files);
      this.setState({
        uploading: true
      });
      await files.forEach(item => {
        const formData = new FormData();
        // console.log(item);
        formData.append("files", item);
        console.log(item);
        if (item.type.indexOf("video") !== -1) {
          let file = this.props.strapi.upload(formData).then(res => {
            let video = {
              vid: res
            };
            this.props.strapi
              .updateEntry("infos", String(video.id), video)
              .then(res => {
                this.props.loadDb();
                this.setState({
                  uploading: false
                });
              });
          });
        }
      });
    } catch (err) {
      this.setState({
        uploading: false,
        uploadError: true
      });
      alert(err);
      console.error(err);
    }
  }

  onImgClick = () => {
    // console.log(this.props.data.onHome);
    if (this.props.data.onHome === true) {
      this.props.updateOnHome(false);
    } else {
      this.props.updateOnHome(true);
    }
  };

  render() {
    const { data } = this.props;
    // console.log("video : ", data);
    // console.log(output);
    // console.log(
    //   cl.video_url(data.vid.public_id, {
    //     format: "gif",
    //     videoSampling: "40",
    //     delay: "200",
    //     width: Math.round(208 * 2),
    //     effect: "loop",
    //     crop: "scale"
    //   })
    //   // .toHtml()
    // );
    console.log(this.props);
    return (
      <Wrapper>
        <Image
          onClick={() => this.imgInput.click()}
          ref={this.dropRef}
          type="file"
          style={{
            backgroundImage:
              data.vid &&
              `url(${cl.video_url(data.vid.public_id, {
                format: "gif",
                videoSampling: "40",
                delay: "200",
                width: Math.round(550 * 2),
                effect: "loop",
                crop: "scale"
              })})`
          }}
        />
        <input
          ref={div => (this.imgInput = div)}
          type="file"
          accept=".jpg, .jpeg, .png, .mp4"
          onChange={e =>
            this.updateVideo(this.props.data, Object.values(e.target.files))
          }
          multiple
          style={{
            opacity: 0,
            width: "0.01px",
            height: "0.01px",
            display: "none"
          }}
        />
        <Title colorMode={this.props.colorMode}>HOME VIDEO</Title>
        <UploadWrapper uploading={this.state.uploading}>
          <Loader colorMode={this.props.colorMode} />
        </UploadWrapper>
      </Wrapper>
    );
  }
}

export default Video;
