import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import Input from "./Input.js";
import cloudinary from "cloudinary-core";

const cl = new cloudinary.Cloudinary({ cloud_name: "escourbiac" });

const Plus = props => (
  <svg width={83} height={82} {...props}>
    <g stroke="#979797" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M41.5.5v80.572M82 41H1.428" />
    </g>
  </svg>
);

const SerieIcon = props => (
  <svg width={39} height={38} {...props}>
    <defs>
      <filter
        x="-113.2%"
        y="-119.4%"
        width="326.3%"
        height="338.9%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={5}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.189368207 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#prefix__a)"
      transform="translate(10 9)"
      fill="#FFF"
      fillRule="evenodd"
    >
      <path d="M16 3h2a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1h11a1 1 0 0 0 1-1V3z" />
      <rect width={15} height={15} rx={1} />
    </g>
  </svg>
);

const Wrapper = styled.div`
  width: 275px;
  margin: 23px;
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 208px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ uploading }) => (uploading ? 0.8 : 0)};
  /* opacity: 0.8; */
  pointer-events: ${({ uploading }) => (uploading ? "auto" : "none")};
  transition: opacity 0.2s ease-in;
`;

const loaderAnim = keyframes`
  0%{
    transform: scale(0.3)
  }
  50%{
    transform: scale(0.6)
  }
  100%{
    transform: scale(0.3)
  }
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  transform: scale(0.3);
  border-radius: 100%;
  background: #000;
  animation: ${loaderAnim} 2s ease-in-out infinite;
`;

const Image = styled.div`
  width: 100%;
  height: 208px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(231, 231, 231);
  position: relative;
  cursor: pointer;
  & svg {
    position: absolute;
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    right: 15px;
    top: 5px;
  }
`;

const ImgInput = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.5s ease;
  opacity: ${({ drag }) => (drag ? 1 : 0)};
  & svg {
    width: 82px;
    height: 82px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
`;

const PhotoLen = styled.div`
  font-family: "VCROSDMono";
  font-size: 13px;
  text-align: left;
  margin: 0 10px;
  color: ${({ colorMode }) => colorMode.textColor};
`;

class Serie extends Component {
  constructor(props) {
    super(props);
    this.dropRef = React.createRef();
    this.state = {
      drag: false
    };
  }

  dragCounter = 0;

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0 &&
      this.props.data.id
    ) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (this.props.data.id) {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // console.log(Object.values(e.dataTransfer.files));
        let videos = Object.values(e.dataTransfer.files).filter(
          file => file.type.indexOf("video") !== -1
        );
        const photos = Object.values(e.dataTransfer.files).filter(
          file => file.type.indexOf("image") !== -1
        );
        // console.log(videos);
        if (photos.length > 0) {
          this.props.addPhotos(photos);
        }
        if (videos.length > 0) {
          this.props.addVideos(videos);
        }

        e.dataTransfer.clearData();
        this.dragCounter = 0;
      }
    }
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  render() {
    const { data } = this.props;
    // console.log(data);
    return (
      <Wrapper>
        <Image
          ref={this.dropRef}
          style={{
            backgroundImage:
              (data.photos &&
                data.photos[0] &&
                data.photos[0].img &&
                `url(${cl.url(data.photos[0].img.public_id, {
                  width: Math.round(208 * 2),
                  crop: "fit"
                })})`) ||
              (data.videos &&
                data.videos[0] &&
                data.videos[0].vid &&
                `url(${cl.video_url(
                  "v1" +
                    data.videos[0].vid.name.substr(
                      0,
                      data.videos[0].vid.name.lastIndexOf(".")
                    ) +
                    "/" +
                    data.videos[0].vid.public_id +
                    ".gif",
                  {
                    videoSampling: "100",
                    delay: "200",
                    width: Math.round(208 * 2),
                    effect: "loop",
                    crop: "scale"
                  }
                )})`)
          }}
          onClick={() => this.props.history.push("/admin/series/" + data.id)}
        >
          <SerieIcon />
          <ImgInput type="file" drag={this.state.drag}>
            <Plus />
          </ImgInput>
          <LoaderWrapper uploading={this.props.uploading || !data.id}>
            <LoaderWrapper uploading={this.props.uploading || !data.id}>
              <Loader></Loader>
            </LoaderWrapper>
          </LoaderWrapper>
        </Image>
        <Input
          title={data.title}
          colorMode={this.props.colorMode}
          deleteSerie={() => this.props.deleteSerie(data.id)}
          updateSerieTitle={newTitle =>
            this.props.updateSerieTitle(newTitle, data.id)
          }
        />
        <div style={{ display: "flex" }}>
          <PhotoLen colorMode={this.props.colorMode}>
            {data.photos && data.photos.length} PHOTOS
          </PhotoLen>
          <PhotoLen colorMode={this.props.colorMode}>
            {data.videos && data.videos.length} VIDEOS
          </PhotoLen>
          <PhotoLen colorMode={this.props.colorMode}>
            {data.photos &&
              data.videos &&
              data.photos.filter(item => item.onHome === true).length +
                data.videos.filter(item => item.onHome === true).length}{" "}
            HOME
          </PhotoLen>
        </div>
      </Wrapper>
    );
  }
}

export default Serie;
